import React, { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../Payment/firebase'; // Adjust path based on your structure
import './CustomerProfile.css';

const CustomerProfile = ({ userId }) => {
  const [customerInfo, setCustomerInfo] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);

  useEffect(() => {
    if (!userId) {
      setError('Invalid user ID');
      setLoading(false);
      return;
    }

    // Fetch customer profile and order data
    const fetchCustomerData = async () => {
      try {
        // Query Firestore to find the customer profile by uid field
        const customerQuery = query(collection(db, 'customerProfiles'), where('uid', '==', userId));
        const customerSnapshot = await getDocs(customerQuery);

        if (!customerSnapshot.empty) {
          const customerDoc = customerSnapshot.docs[0].data();
          setCustomerInfo(customerDoc);
        } else {
          setError('Customer profile not found');
        }

        // Fetch previous orders
        const ordersQuery = query(
          collection(db, 'orders'),
          where('customerId', '==', userId)
        );
        const ordersSnapshot = await getDocs(ordersQuery);

        const ordersList = ordersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setOrders(ordersList);
      } catch (error) {
        console.error('Error fetching customer data:', error);
        setError('Failed to load customer data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, [userId]);

  const handlePasswordChange = async () => {
    if (!newPassword) {
      setError('Please enter a new password.');
      return;
    }

    try {
      setIsUpdatingPassword(true);
      // Find the customer profile by userId (uid)
      const customerQuery = query(collection(db, 'customerProfiles'), where('uid', '==', userId));
      const customerSnapshot = await getDocs(customerQuery);

      if (!customerSnapshot.empty) {
        const customerDocRef = customerSnapshot.docs[0].ref;
        // Update the password in Firestore
        await updateDoc(customerDocRef, {
          password: newPassword,
        });
        alert('Password updated successfully!');
        setNewPassword('');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      setError('Failed to update password. Please try again.');
    } finally {
      setIsUpdatingPassword(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="customer-profile-container">
      <h2>Account Information</h2>
      {customerInfo && (
        <div className="account-info">
          <p><strong>First Name:</strong> {customerInfo.firstName}</p>
          <p><strong>Last Name:</strong> {customerInfo.lastName}</p>
          <p><strong>Email:</strong> {customerInfo.email}</p>
          <p><strong>Account Name:</strong> {customerInfo.accountName}</p>
          <p><strong>Password:</strong> *******</p> {/* We don't display the actual password */}
          {customerInfo.profilePicture && (
            <img
              src={customerInfo.profilePicture}
              alt={`${customerInfo.firstName}'s Profile`}
              className="profile-picture"
            />
          )}
          <div className="password-change-section">
            <h4>Change Password</h4>
            <input
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <button
              onClick={handlePasswordChange}
              disabled={isUpdatingPassword}
            >
              {isUpdatingPassword ? 'Updating...' : 'Update Password'}
            </button>
          </div>
        </div>
      )}

      <h2>Previous Orders</h2>
      {orders.length > 0 ? (
        <div className="order-history">
          {orders.map((order) => (
            <div key={order.id} className="order-item">
              <p><strong>Order ID:</strong> {order.id}</p>
              <p><strong>Date:</strong> {new Date(order.orderDate.seconds * 1000).toLocaleDateString()}</p>
              <p><strong>Total:</strong> ${order.totalAmount.toFixed(2)}</p>
              <p><strong>Status:</strong> {order.status}</p>
              <div className="order-details">
                <h4>Items:</h4>
                {order.items.map((item, index) => (
                  <div key={index} className="order-item-detail">
                    <p>{item.title} - ${item.price}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No previous orders found.</p>
      )}
    </div>
  );
};

export default CustomerProfile;
