import React, { useState, useEffect } from 'react';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore'; // Firestore imports
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // Storage functions
import { db, storage } from './Payment/firebase'; // Firebase instance
import './StoreInformation.css'; // Import the unique stylesheet

// Social Media Share URLs
const FACEBOOK_SHARE_URL = 'https://www.facebook.com/sharer/sharer.php?u=';

const StoreInformation = ({ floristProfile }) => {
  const [items, setItems] = useState([]); // Initialize state for store items
  const [occasions, setOccasions] = useState(['All']); // Track list of occasions
  const [newOccasion, setNewOccasion] = useState(''); // For new occasion input
  const [selectedOccasion, setSelectedOccasion] = useState('All'); // Selected tab
  const [draggedItem, setDraggedItem] = useState(null); // Track the item being dragged
  const [isStoreOnline, setIsStoreOnline] = useState(false); // Track if the store is online
  const [uploadProgress, setUploadProgress] = useState(0); // Track upload progress
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false); // Track advanced settings visibility
  const [newItem, setNewItem] = useState({
    type: 'flower', // Type of item ('flower' or 'accessory')
    title: '',
    image: '',
    description: '',
    price: '',
    occasion: 'All', // Assign an occasion by default
  });
  const [itemType, setItemType] = useState('flower'); // Track whether adding flower or accessory

  // New state for discount code creation
  const [discountCode, setDiscountCode] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState('');
  const [discountExpiry, setDiscountExpiry] = useState('');
  const [discounts, setDiscounts] = useState([]); // Array to hold discount codes

  // New state for video upload
  const [video, setVideo] = useState(null);
  const [videoURL, setVideoURL] = useState('');

  // Fetch store data from Firestore
  useEffect(() => {
    const fetchStoreFromFirestore = async () => {
      if (floristProfile) {
        try {
          const storeRef = doc(db, 'stores', floristProfile.accountName.trim());
          const storeSnap = await getDoc(storeRef);
          if (storeSnap.exists()) {
            const storeData = storeSnap.data();
            const firestoreOccasions = storeData.occasions || [];
            const uniqueOccasions = Array.from(new Set(['All', ...firestoreOccasions]));
            setOccasions(uniqueOccasions);
            setItems(storeData.items || []);
            setIsStoreOnline(storeData.isOnline || false); // Set online status
            setDiscounts(storeData.discounts || []); // Fetch discounts from Firestore
            setVideoURL(storeData.videoURL || ''); // Fetch video URL if available
          } else {
            console.warn(`No store found for ${floristProfile.accountName}`);
          }
        } catch (error) {
          console.error('Error fetching store from Firestore:', error);
        }
      }
    };

    fetchStoreFromFirestore();
  }, [floristProfile]);

  // Save updated store data to Firestore
  const saveStoreToFirestore = async (updatedItems, updatedOccasions = occasions, updatedDiscounts = discounts, updatedVideoURL = videoURL) => {
    try {
      const storeRef = doc(db, 'stores', floristProfile.accountName.trim());
      await setDoc(storeRef, { items: updatedItems, occasions: updatedOccasions, discounts: updatedDiscounts, videoURL: updatedVideoURL }, { merge: true });
    } catch (error) {
      console.error('Error saving store to Firestore:', error);
    }
  };

  // Function to add a new discount code with initial usage count
  const handleAddDiscount = async (e) => {
    e.preventDefault();
    if (discountCode && discountPercentage && discountExpiry) {
      const newDiscount = {
        code: discountCode,
        percentage: discountPercentage,
        expiry: discountExpiry,
        usageCount: 0, // Initialize usage count to 0
      };

      const updatedDiscounts = [...discounts, newDiscount];
      setDiscounts(updatedDiscounts);
      await saveStoreToFirestore(items, occasions, updatedDiscounts);

      // Clear the form
      setDiscountCode('');
      setDiscountPercentage('');
      setDiscountExpiry('');
    } else {
      alert('Please fill out all fields for the discount.');
    }
  };

  // Toggle store online/offline status
  const toggleStoreStatus = async () => {
    try {
      const updatedStatus = !isStoreOnline;
      const storeRef = doc(db, 'stores', floristProfile.accountName.trim());
      await updateDoc(storeRef, { isOnline: updatedStatus });
      setIsStoreOnline(updatedStatus);
    } catch (error) {
      console.error('Error updating store status:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewItem((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewItem((prevData) => ({
          ...prevData,
          image: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddItem = async (e) => {
    e.preventDefault();
    if (newItem.title && newItem.image && newItem.description && newItem.price) {
      const updatedItems = [...items, { ...newItem, type: itemType }];
      setItems(updatedItems);
      setNewItem({ title: '', image: '', description: '', price: '', type: itemType, occasion: 'All' });

      // Save the updated store to Firestore
      await saveStoreToFirestore(updatedItems);
    } else {
      alert('Please fill out all fields before adding the item.');
    }
  };

  const handleDeleteItem = async (indexToRemove) => {
    const updatedItems = items.filter((_, index) => index !== indexToRemove);
    setItems(updatedItems);

    // Save the updated store to Firestore
    await saveStoreToFirestore(updatedItems);
  };

  const handleShare = (item) => {
    const itemUrl = encodeURIComponent(`https://miniflora.com/store/${floristProfile.accountName}/item/${item.title}`);
    const shareUrl = `${FACEBOOK_SHARE_URL}${itemUrl}`;
    window.open(shareUrl, '_blank');
  };

  const handleAddOccasion = (e) => {
    e.preventDefault();
    if (newOccasion && !occasions.includes(newOccasion)) {
      const updatedOccasions = [...occasions, newOccasion];
      setOccasions(updatedOccasions);
      setNewOccasion(''); // Reset input
      saveStoreToFirestore(items, updatedOccasions); // Save the new occasion
    } else {
      alert('Please enter a unique occasion name.');
    }
  };

  const toggleItemType = (type) => {
    setItemType(type);
    setNewItem({ title: '', image: '', description: '', price: '', type: type, occasion: 'All' });
  };

  const filteredItems = items.filter((item) =>
    selectedOccasion === 'All' ? item.type === itemType : item.occasion === selectedOccasion && item.type === itemType
  );

  const handleDragStart = (item) => {
    setDraggedItem(item); // Store the item being dragged
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Allow drop
  };

  const handleDrop = (occasion) => {
    if (draggedItem) {
      const updatedItems = items.map((item) =>
        item.title === draggedItem.title ? { ...item, occasion: occasion } : item
      );
      setItems(updatedItems);
      saveStoreToFirestore(updatedItems); // Save updated items with new occasion
      setDraggedItem(null); // Reset dragged item
    }
  };

  const handleDeleteOccasion = (occasionToDelete) => {
    if (occasionToDelete === 'All') {
      alert('You cannot delete the "All" category.');
      return;
    }

    const updatedItems = items.map((item) =>
      item.occasion === occasionToDelete ? { ...item, occasion: 'All' } : item
    );

    const updatedOccasions = occasions.filter((occasion) => occasion !== occasionToDelete);
    setOccasions(updatedOccasions);
    setItems(updatedItems);
    setSelectedOccasion('All'); // Reset the selected tab

    saveStoreToFirestore(updatedItems, updatedOccasions);
  };

  // Function to handle video upload and restriction check
  const handleVideoUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 5 * 1024 * 1024) { // Check if the video file is under 5 MB
      const storageRef = ref(storage, `videos/${floristProfile.accountName}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      // Monitor upload progress
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress); // Set upload progress for display if needed
        },
        (error) => {
          console.error('Error uploading video:', error);
        },
        async () => {
          // Upload completed successfully, get the download URL
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setVideoURL(downloadURL); // Set video URL for preview and storage
            alert('Video uploaded successfully!');
          } catch (error) {
            console.error('Error getting video URL:', error);
          }
        }
      );
    } else {
      alert('Please upload a video file smaller than 5 MB.');
    }
  };

  const handleSaveVideo = async (e) => {
    e.preventDefault();
    if (videoURL) {
      await saveStoreToFirestore(items, occasions, discounts, videoURL);
      alert('Video saved successfully!');
    } else {
      alert('Please upload a video file first.');
    }
  };

  return (
    <div className="store-info-page-unique">
      <h2>{floristProfile.accountName}'s Store Information</h2>

      {/* Toggle Online/Offline status */}
      <div className="store-status-unique">
        <p>Status: {isStoreOnline ? 'Online' : 'Offline'}</p>
        <label className="switch">
          <input
            type="checkbox"
            className="toggle"
            checked={isStoreOnline}
            onChange={toggleStoreStatus} // Toggling the store status
          />
          <span className="slider"></span>
          <span className="card-side"></span>
        </label>
      </div>

      <div className="store-content-unique">
        {/* Left block for the form */}
        <div className="store-left-block-unique">
          {/* Toggle Buttons for Adding Flowers or Accessories */}
          <div className="toggle-buttons-unique">
            <button className={itemType === 'flower' ? 'active' : ''} onClick={() => toggleItemType('flower')}>
              Add Flower
            </button>
            <button className={itemType === 'accessory' ? 'active' : ''} onClick={() => toggleItemType('accessory')}>
              Add Accessory
            </button>
          </div>

          {/* Upload form */}
          <form className="store-form-unique" onSubmit={handleAddItem}>
            <div className="form-group-unique">
              <label htmlFor="title">{itemType === 'flower' ? 'Flower Title:' : 'Accessory Title:'}</label>
              <input
                type="text"
                id="title"
                name="title"
                value={newItem.title}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group-unique">
              <label htmlFor="image">Upload {itemType === 'flower' ? 'Flower' : 'Accessory'} Image:</label>
              <input type="file" id="image" name="image" accept="image/*" onChange={handleImageUpload} required />
            </div>
            <div className="form-group-unique">
              <label htmlFor="description">{itemType === 'flower' ? 'Flower' : 'Accessory'} Description:</label>
              <input
                type="text"
                id="description"
                name="description"
                value={newItem.description}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group-unique">
              <label htmlFor="price">{itemType === 'flower' ? 'Flower' : 'Accessory'} Price:</label>
              <input type="number" id="price" name="price" value={newItem.price} onChange={handleChange} required />
            </div>
            <div className="form-group-unique">
              <label htmlFor="occasion">Select Occasion:</label>
              <select id="occasion" name="occasion" value={newItem.occasion} onChange={handleChange} required>
                {occasions.map((occasion, index) => (
                  <option key={index} value={occasion}>
                    {occasion}
                  </option>
                ))}
              </select>
            </div>
            <button type="submit" className="btn-unique">
              Add {itemType === 'flower' ? 'Flower' : 'Accessory'}
            </button>
          </form>

          {/* Add new occasion */}
          <form onSubmit={handleAddOccasion}>
            <div className="form-group-unique">
              <label htmlFor="newOccasion">Create New Occasion:</label>
              <input
                type="text"
                id="newOccasion"
                name="newOccasion"
                value={newOccasion}
                onChange={(e) => setNewOccasion(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn-unique">Add Occasion</button>
          </form>

          {/* Advanced Settings Toggle */}
          <button
            className="toggle-button-unique"
            onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
          >
            {showAdvancedSettings ? 'Hide Advanced Settings' : 'Show Advanced Settings'}
          </button>

          {/* Advanced Settings Section */}
          {showAdvancedSettings && (
            <div className="advanced-settings-unique">
              {/* Discount Code Block */}
              <form className="store-form-unique" onSubmit={handleAddDiscount}>
                <h3>Create Discount Code</h3>
                <div className="form-group-unique">
                  <label htmlFor="discountCode">Discount Code:</label>
                  <input
                    type="text"
                    id="discountCode"
                    name="discountCode"
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group-unique">
                  <label htmlFor="discountPercentage">Discount Percentage:</label>
                  <input
                    type="number"
                    id="discountPercentage"
                    name="discountPercentage"
                    value={discountPercentage}
                    onChange={(e) => setDiscountPercentage(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group-unique">
                  <label htmlFor="discountExpiry">Expiry Date:</label>
                  <input
                    type="date"
                    id="discountExpiry"
                    name="discountExpiry"
                    value={discountExpiry}
                    onChange={(e) => setDiscountExpiry(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn-unique">
                  Add Discount Code
                </button>
              </form>

              {/* Display list of active discount codes */}
              <h3>Current Discount Codes</h3>
              {discounts.length > 0 ? (
                <ul className="discount-list-unique">
                  {discounts.map((discount, index) => (
                    <li key={index} className="discount-item-unique">
                      <p><strong>Code:</strong> {discount.code}</p>
                      <p><strong>Discount:</strong> {discount.percentage}%</p>
                      <p><strong>Expiry:</strong> {discount.expiry}</p>
                      <p><strong>Usage Count:</strong> {discount.usageCount} times used</p> {/* Display usage count */}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No discount codes available.</p>
              )}

              {/* Video Upload Form */}
              <form className="store-form-unique" onSubmit={handleSaveVideo}>
                <h3>Upload Promotional Video</h3>
                <div className="form-group-unique">
                  <label htmlFor="videoUpload">Upload Video (max 5 MB):</label>
                  <input type="file" id="videoUpload" name="video" accept="video/*" onChange={handleVideoUpload} required />
                </div>

                {/* Display the upload progress */}
                {uploadProgress > 0 && uploadProgress < 100 && (
                  <p>Uploading: {Math.round(uploadProgress)}%</p>
                )}
                {videoURL && (
                  <div className="video-preview-unique">
                    <video width="100%" controls>
                      <source src={videoURL} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
                <button type="submit" className="btn-unique">Save Video</button>
              </form>
            </div>
          )}
        </div>

        {/* Right block for the item list */}
        <div className="store-right-block-unique">
          {/* Occasion Tabs */}
          <div className="occasion-tabs-unique">
            {occasions.map((occasion, index) => (
              <div key={index} className="occasion-tab-container-unique">
                <button
                  className={`occasion-tab-unique ${selectedOccasion === occasion ? 'active' : ''}`}
                  onClick={() => setSelectedOccasion(occasion)}
                  onDragOver={handleDragOver}
                  onDrop={() => handleDrop(occasion)} // Handle item drop on tab
                >
                  {occasion}
                </button>
                {/* Add delete button except for "All" */}
                {occasion !== 'All' && (
                  <button className="delete-occasion-btn-unique" onClick={() => handleDeleteOccasion(occasion)}>
                    ✕
                  </button>
                )}
              </div>
            ))}
          </div>

          <h3>Your {itemType === 'flower' ? 'Flowers' : 'Accessories'} for {selectedOccasion}</h3>
          <div className="item-list-unique">
            {filteredItems.map((item, index) => (
              <div
                key={index}
                className="item-unique"
                draggable
                onDragStart={() => handleDragStart(item)} // Start dragging an item
              >
                {item.image && <img src={item.image} alt={itemType} className="item-image-unique" />}
                <p><strong>Title:</strong> {item.title}</p>
                <p><strong>Description:</strong> {item.description}</p>
                <p><strong>Price:</strong> ${item.price}</p>

                <button className="share-btn-unique" onClick={() => handleShare(item)}>
                  Share on Facebook
                </button>
                <button className="delete-btn-unique" onClick={() => handleDeleteItem(index)}>
                  Delete
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreInformation;
