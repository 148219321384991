import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from './Payment/firebase';
import { addDoc, collection } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid'; // For generating unique UID
import './SignUp.css';

const SignUpPage = () => {
  const [accountType, setAccountType] = useState(''); // New state to track account type (Personal or Business)
  const [sinOrBn, setSinOrBn] = useState(''); // State to hold either SIN or BN
  const [accountName, setAccountName] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false); // Checkbox state
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!hasAgreed) {
      setError('You must agree to the terms before signing up.');
      return;
    }
  
    if (!accountType) {
      setError('Please select an account type.');
      return;
    }
  
    if (!sinOrBn) {
      setError('Please provide your SIN or Business Number for tax purposes.');
      return;
    }
  
    setLoading(true);
  
    try {
      const generatedUID = uuidv4(); // Generate a new UID for the florist
  
      const newAccount = {
        accountName: accountName.trim(),
        firstName,
        lastName,
        phoneNumber,
        address,
        email,
        profilePicture: '',
        password,
        status: 'pending',
        uid: generatedUID, // Add UID to new account
        accountType, // Include account type (Personal or Business)
        sinOrBn, // Include SIN or BN based on account type
      };
  
      let profilePictureURL = '';
      if (profilePicture) {
        const pictureRef = ref(storage, `profilePictures/${accountName}-${profilePicture.name}`);
        await uploadBytes(pictureRef, profilePicture);
        profilePictureURL = await getDownloadURL(pictureRef);
        newAccount.profilePicture = profilePictureURL;
      }
  
      await addDoc(collection(db, 'floristProfiles'), newAccount);
  
      // Send the email notification to admin
      await fetch('http://localhost:3001/notify-admin-new-application', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,  // New florist's email
          firstName,
          lastName,
          accountName,
        }),
      });
  
      alert('Sign-up request submitted. Admin will review your request and notify you.');
      setLoading(false);
      navigate('/');
    } catch (error) {
      console.error('Error during sign up:', error);
      setError('Failed to submit sign-up request. Please try again.');
      setLoading(false);
    }
  };
  

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicture(e.target.files[0]);
    }
  };

  const handleHomeRedirect = () => {
    navigate('/');
  };

  return (
    <div className="signup-page">
      <h2>Sign Up</h2>
      <form onSubmit={handleSignUp} className="signup-form">
  {/* Left side of the form */}
  <div className="signup-left">
    <input
      type="text"
      placeholder="First Name"
      value={firstName}
      onChange={(e) => setFirstName(e.target.value)}
      required
    />
    <input
      type="text"
      placeholder="Last Name"
      value={lastName}
      onChange={(e) => setLastName(e.target.value)}
      required
    />
    <input
      type="tel"
      placeholder="Phone Number"
      value={phoneNumber}
      onChange={(e) => setPhoneNumber(e.target.value)}
      required
    />
    <input
      type="text"
      placeholder="Address"
      value={address}
      onChange={(e) => setAddress(e.target.value)}
      required
    />
    <input
      type="email"
      placeholder="Email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      required
    />
  </div>

  {/* Right side of the form */}
  <div className="signup-right">
    <input
      type="text"
      placeholder="Account Name"
      value={accountName}
      onChange={(e) => setAccountName(e.target.value)}
      required
    />
    <input
      type="password"
      placeholder="Password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      required
    />

    {/* File upload */}
    <label className="file-upload-label">Profile Picture:</label>
    <input type="file" accept="image/*" onChange={handleFileChange} className="file-upload-input" />

    {/* Account Type Selection */}
    <div className="account-type-selection">
  <p>Please select your account type:</p>
  <div className="radio-option">
    <input
      type="radio"
      id="personal"
      name="accountType"
      value="personal"
      onChange={(e) => {
        setAccountType(e.target.value);
        setSinOrBn(''); // Clear previous SIN/BN value
      }}
    />
    <label htmlFor="personal">Personal Account</label>
  </div>
  
  <div className="radio-option">
    <input
      type="radio"
      id="business"
      name="accountType"
      value="business"
      onChange={(e) => {
        setAccountType(e.target.value);
        setSinOrBn(''); // Clear previous SIN/BN value
      }}
    />
    <label htmlFor="business">Business Account</label>
  </div>
</div>

    {/* Conditionally Render SIN or BN Field */}
    {accountType === 'personal' && (
      <input
        type="text"
        placeholder="Social Insurance Number (SIN)"
        value={sinOrBn}
        onChange={(e) => setSinOrBn(e.target.value)}
        required
      />
    )}
    {accountType === 'business' && (
      <input
        type="text"
        placeholder="Business Number (BN)"
        value={sinOrBn}
        onChange={(e) => setSinOrBn(e.target.value)}
        required
      />
    )}

    <p className="tax-info">
      <strong>Note:</strong> We collect SIN or BN for tax reporting purposes, including issuing T4A slips for income
      paid through the platform.
    </p>
  </div>

  <div className="legal-disclaimer">
    <input
      type="checkbox"
      id="agreeTerms"
      checked={hasAgreed}
      onChange={(e) => setHasAgreed(e.target.checked)}
    />
    <label htmlFor="agreeTerms" className="disclaimer-text">
      I agree to the <strong>Legal Disclaimer:</strong>
      <ul>
        <li>We will take customer payments and release them to you on Mondays, biweekly.</li>
        <li>You are responsible for timely order delivery and quality control.</li>
        <li>MiniFlora is not liable for any damages related to late deliveries or product issues.</li>
        <li>We reserve the right to terminate your account for violations of our policies.</li>
      </ul>
      {error && <p className="error">{error}</p>}
  <button type="submit" className="signup-btn" disabled={loading}>
    {loading ? 'Submitting...' : 'Sign Up'}
  </button>
    </label>
      
  </div>

  </form>

      <button onClick={handleHomeRedirect} className="signup-home-btn">
        Return to Home
      </button>
    </div>
  );
};

export default SignUpPage;
