import React, { useState } from 'react';
import { auth } from '../Payment/firebase';
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../Payment/firebase'; // Add Firestore import
import { useNavigate } from 'react-router-dom';
import './SupplierLogin.css'; // Create a CSS file for styling

const SupplierLogin = ({ setSupplierProfile }) => {
  const [accountName, setAccountName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // For navigation after login

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      // Set Firebase persistence to localStorage for this login session
      await setPersistence(auth, browserLocalPersistence);

      // Query Firestore to find the email associated with the provided accountName
      const supplierQuery = query(
        collection(db, 'suppliers'),
        where('accountName', '==', accountName)
      );
      const supplierSnapshot = await getDocs(supplierQuery);

      if (supplierSnapshot.empty) {
        throw new Error('Account name not found.');
      }

      // Extract the email from the query result
      const supplierData = supplierSnapshot.docs[0].data();
      const email = supplierData.email;

      // Use the retrieved email to log in with Firebase Authentication
      await signInWithEmailAndPassword(auth, email, password);

      // Set the supplier profile state after successful login
      setSupplierProfile(supplierData);

      // Redirect to the supplier's store page
      navigate('/supplier/store');
    } catch (err) {
      console.error('Error logging in supplier:', err);
      setError('Invalid account name or password.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="supplier-login">
      <h1>Supplier Login</h1>
      <form onSubmit={handleLogin}>
        <label>
          Account Name:
          <input
            type="text"
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            required
          />
        </label>

        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </label>

        {error && <p className="error">{error}</p>}

        <button type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
      <p>
        Don't have an account? <a href="/supplier/signup">Sign Up</a>
      </p>
    </div>
  );
};

export default SupplierLogin;
