import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './StorePage.css';
import FlowerModal from './FlowerModal';
import EmptyProfilePic from './EmptyProfile.jpg'; // Default profile picture

// Utility function to sanitize user input
const sanitizeInput = (input) => {
  const element = document.createElement('div');
  element.innerText = input;
  return element.innerHTML;
};

const FACEBOOK_SHARE_URL = 'https://www.facebook.com/sharer/sharer.php?u=';

const TAX_RATE = 0.12;

const StorePage = ({ cart, setCart }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { floristProfile, store } = location.state || {}; // Ensure floristProfile and store are present

  const [selectedItem, setSelectedItem] = useState(null);
  const [activeOccasion, setActiveOccasion] = useState('All'); // State for the active tab

  if (!floristProfile || !store || !store.items) {
    return <div>Store data is not available.</div>;
  }

  const flowers = store.items.filter((item) => item.type === 'flower');

  // Extract unique occasions from flower data
  const uniqueOccasions = ['All', ...new Set(flowers.map(flower => sanitizeInput(flower.occasion)))];

  // Filter flowers based on the selected occasion tab
  const filteredFlowers = activeOccasion === 'All'
    ? flowers
    : flowers.filter(flower => sanitizeInput(flower.occasion) === activeOccasion);

    const handleAddToCart = (item, selectedAccessories = []) => {
      const cartItem = {
        ...item,
        accessories: selectedAccessories,
        floristProfile: { // Add florist information directly into the cart item
          uid: floristProfile.uid,
          accountName: floristProfile.accountName,
          address: floristProfile.address,
        },
      };
      setCart((prevCart) => [...prevCart, cartItem]);
      setSelectedItem(null); // Close modal after adding to cart
    };

  const handleRemoveFromCart = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
  };

  const handleRemoveAccessoryFromCart = (itemIndex, accessoryIndex) => {
    const updatedCart = [...cart];
    const updatedAccessories = updatedCart[itemIndex].accessories.filter((_, index) => index !== accessoryIndex);
    
    if (updatedAccessories.length === 0) {
      delete updatedCart[itemIndex].accessories;
    } else {
      updatedCart[itemIndex].accessories = updatedAccessories;
    }

    setCart(updatedCart);
  };

  const handleOpenModal = (item) => {
    setSelectedItem(item);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
  };

  const calculateTotal = () => {
    return (cart || []).reduce((total, item) => {
      const flowerPrice = parseFloat(item.price) || 0;
      const accessoriesPrice = item.accessories
        ? item.accessories.reduce((acc, accItem) => acc + (parseFloat(accItem.price) || 0), 0)
        : 0;
      return total + flowerPrice + accessoriesPrice;
    }, 0);
  };

  const totalAmount = calculateTotal();
  const taxAmount = totalAmount * TAX_RATE;
  const finalTotal = totalAmount + taxAmount;

  const handleProceedToCheckout = () => {
    // Verify floristProfile before proceeding to checkout
    if (!floristProfile || !floristProfile.uid) {
      alert('Florist information is missing.');
      return;
    }
    
    navigate('/payment', {
      state: {
        cartItems: cart,
        totalAmount,
        floristUid: floristProfile.uid,      // Pass florist UID
        floristAddress: floristProfile.address, // Pass florist address
      },
    });
  };

  const handleShare = (item) => {
    const itemUrl = encodeURIComponent(`https://miniflora.com/store/${sanitizeInput(floristProfile.accountName)}/item/${sanitizeInput(item.title)}`);
    const shareUrl = `${FACEBOOK_SHARE_URL}${itemUrl}`;
    window.open(shareUrl, '_blank');
  };

  const profilePicture = floristProfile.profilePicture ? floristProfile.profilePicture : EmptyProfilePic;

  return (
    <div className="store-page-container-unique">
      <div className={`store-header-content-unique ${!store.videoURL ? 'no-video' : ''}`}>
        <div className="store-title-video-unique">
          <div className="store-header-unique">
            <img
              src={profilePicture}
              alt={`${floristProfile.firstName}'s profile`}
              className="profile-picture-header-unique"
            />
            <h2 className="store-page-header-unique">
              {sanitizeInput(floristProfile.firstName)}'s Mini Store
            </h2>
          </div>
          {store.videoURL && (
            <div className="store-video-container-unique">
              <video className="store-video-unique" autoPlay loop muted playsInline>
                <source src={store.videoURL} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>

        <div className="store-content-container-unique">
          <div className="occasion-tabs-container-unique">
            {uniqueOccasions.map((occasion, index) => (
              <button
                key={index}
                className={`occasion-tab-unique ${activeOccasion === occasion ? 'active' : ''}`}
                onClick={() => setActiveOccasion(occasion)}
              >
                {sanitizeInput(occasion)}
              </button>
            ))}
            <button className="return-home-btn-unique" onClick={() => navigate('/')}>
              Return to Home
            </button>
          </div>

          <div className="merchandise-list-container-unique">
            {filteredFlowers.length > 0 ? (
              filteredFlowers.map((flower, index) => (
                <div key={index} className="flower-item-unique" onClick={() => handleOpenModal(flower)}>
                  <img
                    src={sanitizeInput(flower.image)}
                    alt={sanitizeInput(flower.title)}
                    className="flower-image-unique"
                  />
                  <div className="flower-details-unique">
                    <p className="flower-title-unique">{sanitizeInput(flower.title)}</p>
                    <p className="flower-price-unique">${sanitizeInput(flower.price)}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No flowers available for {activeOccasion}.</p>
            )}
          </div>
        </div>
      </div>

      {selectedItem && (
        <FlowerModal
          flower={selectedItem}
          accessories={store.items.filter((item) => item.type === 'accessory').map((acc) => ({
            ...acc,
            title: sanitizeInput(acc.title),
            price: sanitizeInput(acc.price),
            image: sanitizeInput(acc.image),
          }))}
          onClose={handleCloseModal}
          onAddToCart={handleAddToCart}
        />
      )}
    </div>
  );
};

export default StorePage;
