import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore'; // Firestore imports
import { db } from './Payment/firebase'; // Firestore instance
import './LoginPage.css'; // Import any styles for the login page

const LoginPage = ({ setFloristProfile }) => {
  const [accountName, setAccountName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Query Firestore to find the florist profile by accountName
      const floristCollection = collection(db, 'floristProfiles');
      const q = query(floristCollection, where('accountName', '==', accountName));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Get the first matching document (assuming unique accountName)
        const floristDoc = querySnapshot.docs[0].data();

        // Check if the password matches
        if (floristDoc.password === password) {
          // Login successful
          setFloristProfile(floristDoc);
          alert('Login successful!');
          navigate('/FloristPage');
        } else {
          setError('Invalid account name or password.');
        }
      } else {
        setError('No account found.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError('An error occurred. Please try again.');
    }
  };

  const handleSignUp = () => {
    navigate('/signup'); // Redirect to the florist sign-up page
  };

  const handleReturnHome = () => {
    navigate('/'); // Redirect to the home page
  };

  return (
    <div className="florist-login-page">
  <h2>Florist Login</h2>
  <form onSubmit={handleLogin}>
    <input
      type="text"
      placeholder="Account Name"
      value={accountName}
      onChange={(e) => setAccountName(e.target.value)}
      required
    />
    <input
      type="password"
      placeholder="Password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      required
    />
    {error && <p className="error">{error}</p>}
    <button type="submit" className="btn">Log In</button>
  </form>

  <button onClick={handleSignUp} className="btn signup-btn">Sign Up</button>
  <button onClick={handleReturnHome} className="btn home-btn">Return Home</button>
</div>
  );
};

export default LoginPage;
