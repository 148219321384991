import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AccountInformation.css';
import { doc, getDoc, setDoc, query, collection, where, getDocs } from 'firebase/firestore'; // Ensure all necessary Firestore methods are imported
import { db } from './Payment/firebase';

const AccountInformation = ({ floristProfile, setFloristProfile }) => {
  const [formData, setFormData] = useState(floristProfile || {});
  const [profilePicture, setProfilePicture] = useState(floristProfile?.profilePicture || '');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFloristProfile = async () => {
      if (floristProfile?.uid) {
        try {
          // Query the 'floristProfiles' collection to find the document with the matching UID
          const floristQuery = query(
            collection(db, 'floristProfiles'),
            where('uid', '==', floristProfile.uid) // Match based on the 'uid' field
          );
  
          const querySnapshot = await getDocs(floristQuery);
  
          if (!querySnapshot.empty) {
            // Assuming that UID is unique and only one document will match
            const floristDoc = querySnapshot.docs[0];
            const profileData = floristDoc.data();
            
            // Set the profile data to the component state
            setFormData(profileData);
            setProfilePicture(profileData.profilePicture || '');
  
          } else {
            console.error(`No profile found in Firestore for UID: ${floristProfile.uid}`);
          }
  
        } catch (error) {
          console.error('Error fetching florist profile:', error);
        }
      } else {
        console.error('No UID found in floristProfile.');
      }
    };
  
    fetchFloristProfile();
  }, [floristProfile]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const updatedProfilePicture = reader.result;
        setProfilePicture(updatedProfilePicture);

        const updatedFloristProfile = { ...formData, profilePicture: updatedProfilePicture };
        setFormData(updatedFloristProfile);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const updatedProfile = { ...formData, profilePicture };

      const floristRef = doc(db, 'floristProfiles', formData.uid);

      await setDoc(floristRef, updatedProfile, { merge: true });

      setFloristProfile(updatedProfile);

      alert('Profile updated successfully!');
      navigate('/FloristPage');
    } catch (error) {
      console.error('Error updating profile in Firestore:', error);
    }
  };

  return (
    <div className="account-info-page">
      <h2>Account Information</h2>
      <form className="account-form" onSubmit={handleSubmit}>
        {/* Profile Picture Section */}
        <div className="form-group">
          <label>Profile Picture:</label>
          {profilePicture ? (
            <img src={profilePicture} alt="Profile" className="account-profile-thumbnail" />
          ) : (
            <p>No profile picture uploaded.</p>
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="upload-button"
          />
        </div>

        {/* Other Form Fields */}
        <div className="form-group">
          <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address:</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email Address:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password || ''}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="btn">Save Changes</button>
      </form>
    </div>
  );
};

export default AccountInformation;
