// Account.js
const initialAccounts = [
    {
      accountName: 'Admin123',
      password: '137AB2Cd!',
      role: 'admin',
    },
    // Additional accounts can be manually added here
  ];
  
  // Initialize accounts in localStorage if not already present
  if (!localStorage.getItem('accounts')) {
    localStorage.setItem('accounts', JSON.stringify(initialAccounts));
  }
  
  // Function to retrieve all accounts from localStorage
  export const getAllAccounts = () => {
    const accounts = JSON.parse(localStorage.getItem('accounts')) || [];
    console.log('Retrieved Accounts from localStorage:', accounts); // Debugging output
    return accounts;
  };
  
  // Function to validate login credentials
  export const validateCredentials = (username, password) => {
    console.log('Checking credentials for:', username.trim(), password.trim()); // Debugging output
  
    const allAccounts = getAllAccounts(); // Get all stored accounts
    const matchedAccount = allAccounts.find(
      (account) =>
        account.accountName.trim() === username.trim() && account.password === password.trim()
    );
  
    console.log('Matched Account:', matchedAccount ? matchedAccount : 'No matching account found'); // Debugging output
    return matchedAccount;
  };
  
  // Function to add a new account (e.g., for sign-up)
  export const addNewAccount = (newAccount) => {
    const allAccounts = getAllAccounts();
    allAccounts.push(newAccount);
    localStorage.setItem('accounts', JSON.stringify(allAccounts));
    console.log('New Account Added:', newAccount); // Debugging output
  };
  
  export default { getAllAccounts, validateCredentials, addNewAccount };
  