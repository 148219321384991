import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Payment.css';
import { getDoc, doc, setDoc, getDocs, query, where, collection } from 'firebase/firestore';
import { db } from './firebase';
import { Loader } from '@googlemaps/js-api-loader';

let googleMapsLoader;

const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedFloristProfile, setSelectedFloristProfile] = useState(null);
  const { cartItems = [], totalAmount = 0, floristUid = null, floristAddress = '' } = location.state || {};
  const [isCardAttached, setIsCardAttached] = useState(false);
  const [senderInfo, setSenderInfo] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    message: '',
  });

  const [recipientInfo, setRecipientInfo] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    address: '',
    apartmentNumber: '',
    buzzer: '',
    city: '',
    province: '',
    postalCode: '',
  });

  const [deliveryInfo, setDeliveryInfo] = useState({
    date: '',
    time: 'AM',
  });

  const [paymentInfo, setPaymentInfo] = useState({
    cardNumber: '',
    expiryDate: '',
    securityNumber: '',
  });

  const [tipAmount, setTipAmount] = useState(0);
  const [isConfirming, setIsConfirming] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [loading, setLoading] = useState(false);
  const [specialInstructions, setSpecialInstructions] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [squarePaymentInstance, setSquarePaymentInstance] = useState(null);
  const [discountCode, setDiscountCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [availableDiscounts, setAvailableDiscounts] = useState([]);
  const [legalConsent, setLegalConsent] = useState(false);
  const adminEmail = 'arthurlin78901@hotmail.com';

  const sanitizeInput = (input) => {
    const div = document.createElement('div');
    div.textContent = input;
    return div.innerHTML;
  };

  const sanitizedSenderInfo = {
    ...senderInfo,
    firstName: sanitizeInput(senderInfo.firstName),
    lastName: sanitizeInput(senderInfo.lastName),
    email: sanitizeInput(senderInfo.email),
    message: sanitizeInput(senderInfo.message),
  };

  const sanitizedRecipientInfo = {
    ...recipientInfo,
    firstName: sanitizeInput(recipientInfo.firstName),
    lastName: sanitizeInput(recipientInfo.lastName),
    email: sanitizeInput(recipientInfo.email),
    address: sanitizeInput(recipientInfo.address),
    apartmentNumber: sanitizeInput(recipientInfo.apartmentNumber),
    buzzer: sanitizeInput(recipientInfo.buzzer),
    city: sanitizeInput(recipientInfo.city),
    province: sanitizeInput(recipientInfo.province),
    postalCode: sanitizeInput(recipientInfo.postalCode),
  };

  const sanitizedPaymentInfo = {
    ...paymentInfo,
    cardNumber: paymentInfo.cardNumber.replace(/\D/g, ''),
    expiryDate: sanitizeInput(paymentInfo.expiryDate),
    securityNumber: sanitizeInput(paymentInfo.securityNumber),
  };

  const processPayment = async (nonce) => {
    try {
      const response = await fetch('https://api-495106690462.us-central1.run.app/charge', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nonce, totalAmount: Number(calculateTotalAmount()) }), // Convert totalAmount to Number
      });
      const data = await response.json();
  
      if (data.success) {
        alert('Payment successful!');
        navigate('/');
      } else {
        alert('Payment failed: ' + data.error);
      }
    } catch (error) {
      console.error('Error processing payment:', error);
    }
  };
  
  useEffect(() => {
    const initializeSquarePayment = async () => {
      if (!window.Square || !window.Square.payments) {
        console.error("Square library is not loaded correctly. Check the script URL.");
        return;
      }
      const payments = window.Square.payments('sandbox-sq0idb-fiYyO94vD7MJdbVCzqPODg', 'L0N5Q7DGD2X3R');
  
      try {
        const card = await payments.card();
          
        const cardContainer = document.getElementById("card-container");
        if (cardContainer && !cardContainer.hasChildNodes()) { // Check if card has been attached
          await card.attach("#card-container");
          
          setIsCardAttached(true);  // Set this to true after successful attachment
          setSquarePaymentInstance(card);
        }
  
        card.addEventListener("cardNonceResponseReceived", (errors, nonce) => {
          if (errors) {
            console.error("Errors generating nonce:", errors);
            alert("Payment failed: " + errors[0].message);
            return;
          }
          console.log("Nonce received:", nonce);
          processPayment(nonce);
        });
  
        setSquarePaymentInstance(card);
  
      } catch (error) {
        console.error("Error initializing or attaching card:", error);
      }
    };
  
    initializeSquarePayment();
  }, []); // Only runs on mount
  
  
  
  useEffect(() => {
    const fetchFloristData = async () => {
      if (!floristUid) {
        console.error('No florist UID provided.');
        return;
      }

      try {
        const floristQuery = query(collection(db, 'floristProfiles'), where('uid', '==', floristUid));
        const floristSnapshot = await getDocs(floristQuery);

        if (!floristSnapshot.empty) {
          const floristData = floristSnapshot.docs[0].data();
          setSelectedFloristProfile(floristData);

          const storeRef = doc(db, 'stores', floristData.accountName);
          const storeSnapshot = await getDoc(storeRef);

          if (storeSnapshot.exists()) {
            const storeData = storeSnapshot.data();
            setAvailableDiscounts(storeData.discounts || []);
          } else {
            console.warn('No store found for this florist.');
          }
        } else {
          console.error(`No florist found for UID: ${floristUid}`);
        }
      } catch (error) {
        console.error('Error fetching florist data:', error);
      }
    };

    fetchFloristData();
  }, [floristUid]);

  useEffect(() => {
    if (recipientInfo.address && selectedFloristProfile && selectedFloristProfile.address) {
      calculateDeliveryFee();
    }
  }, [recipientInfo, selectedFloristProfile]);

  const calculateTax = (amount) => amount * 0.12;

  const applyDiscount = () => {
    const discount = availableDiscounts.find(
      (disc) => disc.code.toLowerCase() === discountCode.toLowerCase() && new Date(disc.expiry) > new Date()
    );

    if (discount) {
      const discountValue = (totalAmount * (discount.percentage / 100)).toFixed(2);
      setDiscountAmount(parseFloat(discountValue));
    } else {
      alert('Invalid discount code or expired.');
      setDiscountAmount(0);
    }
  };

  const calculateTotalAmount = () => {
    const validTotalAmount = cartItems.reduce((sum, item) => {
      const itemPrice = parseFloat(item.price);
      const accessoriesPrice = item.accessories
        ? item.accessories.reduce((accSum, acc) => accSum + parseFloat(acc.price) * acc.quantity, 0)
        : 0;
      return sum + itemPrice + accessoriesPrice;
    }, 0);
  
    const tax = calculateTax(validTotalAmount);
    const validDeliveryFee = parseFloat(deliveryFee) || 0;
    const validTipAmount = parseFloat(tipAmount) || 0;
  
    return Number((validTotalAmount + tax + validDeliveryFee + validTipAmount - discountAmount).toFixed(2));
  };
  

  const handleChange = (e, setFunction) => {
    const { name, value } = e.target;
    setFunction((prevInfo) => ({
      ...prevInfo,
      [name]: sanitizeInput(value),
    }));
  };

  const checkIfInServiceArea = (recipientInfo) => {
    const serviceableCities = [
      'Mission', 'Chilliwack', 'Abbotsford', 'Vancouver', 'Burnaby', 'Richmond', 'New Westminster', 'Surrey',
      'Langley City', 'Coquitlam', 'Port Coquitlam', 'Port Moody', 'North Vancouver City', 'West Vancouver',
      'White Rock', 'Delta', 'Pitt Meadows', 'Maple Ridge', 'Langley', 'Anmore', 'Belcarra',
    ];

    const isCityInServiceArea = serviceableCities.includes(recipientInfo.city.trim());

    if (!isCityInServiceArea) {
      alert(`Our services are currently unavailable in ${recipientInfo.city}.`);
    }

    return isCityInServiceArea;
  };

  const calculateDeliveryFee = async () => {
    
    try {
      if (!window.google || !window.google.maps) {
        console.log("Loading Google Maps API...");

        if (!googleMapsLoader) {
          googleMapsLoader = new Loader({
            apiKey: 'YOUR_GOOGLE_API_KEY',
            version: 'weekly',
            libraries: ['places'],
          });
        }

        await googleMapsLoader.load();
        console.log("Google Maps API loaded");
      }

      const origin = selectedFloristProfile?.address;
      const destination = recipientInfo?.address;

      if (!origin || !destination) {
        console.error("Missing origin or destination address.");
        setDeliveryFee(0);
        return;
      }

      const service = new window.google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          
          if (status === 'OK' && response.rows[0].elements[0].status === 'OK') {
            const distanceInKm = response.rows[0].elements[0].distance.value / 1000;
            const calculatedDeliveryFee = distanceInKm < 10 ? 10 : distanceInKm;
            setDeliveryFee(calculatedDeliveryFee);
            
          } else {
            console.error("Error calculating distance or invalid response status:", status);
            setDeliveryFee(0);
          }
        }
      );
    } catch (error) {
      console.error("Error loading Google Maps API or calculating delivery fee:", error);
      setDeliveryFee(0);
    }
  };

  const handleConfirm = async () => {
    if (!legalConsent) {
        alert('Please agree to the legal disclaimer before submitting your order.');
        return;
    }

    if (!isCardAttached) {  // Check if the card is attached
        alert("The payment form is not fully loaded. Please wait a moment and try again.");
        return;
    }

    setShowLoader(true);
    const isInServiceArea = checkIfInServiceArea(recipientInfo);

    if (!isInServiceArea) {
        setShowLoader(false);
        return;
    }

    if (loading) return;

    setLoading(true);

    if (!selectedFloristProfile || !selectedFloristProfile.uid) {
        alert('Florist information is missing.');
        setLoading(false);
        return;
    }

    try {
        // Request Square's payment form to generate a nonce (token)
        const result = await squarePaymentInstance.tokenize();

        if (result.status === 'OK') {
            const nonce = result.token;
            console.log("Received nonce:", nonce);

            // Continue with the payment process
            await processPayment(nonce);

            // Continue with order creation and notification logic
            // ... (your existing code here)

        } else {
            console.error("Error generating card token:", result.errors);
            alert('Payment error. Please try again.');
        }
    } finally {
        setShowLoader(false);
        setLoading(false);
    }
};


  return (
    <div className="payment-page">
      {showLoader && (
        <div className="loader-wrapper">
          <div className="blue ball"></div>
          <div className="red ball"></div>
          <div className="yellow ball"></div>
          <div className="green ball"></div>
        </div>
      )}

      <h2>Payment Information</h2>

      {!isConfirming ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setIsConfirming(true);
          }}
          className="payment-form"
        >
          <div className="payment-left-block">
            <h3>Sender Information</h3>
            <div className="payment-info-group">
              <label className="payment-label">First Name</label>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={senderInfo.firstName}
                maxLength="20"
                onChange={(e) => handleChange(e, setSenderInfo)}
                required
                className="payment-input"
              />
              <label className="payment-label">Last Name</label>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={senderInfo.lastName}
                maxLength="20"
                onChange={(e) => handleChange(e, setSenderInfo)}
                required
                className="payment-input"
              />
              <label className="payment-label">Phone</label>
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Phone Number"
                value={senderInfo.phoneNumber}
                maxLength="10"
                onChange={(e) => handleChange(e, setSenderInfo)}
                required
                className="payment-input"
              />
              <label className="payment-label">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={senderInfo.email}
                maxLength="32"
                onChange={(e) => handleChange(e, setSenderInfo)}
                required
                className="payment-input"
              />
              <label className="payment-label">Message for Card</label>
              <textarea
                name="message"
                placeholder="Write a message for the florist to include on the card"
                value={senderInfo.message}
                maxLength="50"
                onChange={(e) => handleChange(e, setSenderInfo)}
                className="payment-textarea"
              />

              <label className="payment-label">Special Instructions</label>
              <textarea
                name="specialInstructions"
                placeholder="Add any special instructions for the florist"
                value={specialInstructions}
                maxLength="50"
                onChange={(e) => setSpecialInstructions(sanitizeInput(e.target.value))}
                className="payment-textarea"
              />
            </div>

            <h3>Recipient Information</h3>
            <div className="payment-info-group">
              <label className="payment-label">First Name</label>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={recipientInfo.firstName}
                maxLength="20"
                onChange={(e) => handleChange(e, setRecipientInfo)}
                required
                className="payment-input"
              />
              <label className="payment-label">Last Name</label>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                maxLength="20"
                value={recipientInfo.lastName}
                onChange={(e) => handleChange(e, setRecipientInfo)}
                required
                className="payment-input"
              />
              <label className="payment-label">Phone Number</label>
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Phone Number"
                value={recipientInfo.phoneNumber}
                maxLength="10"
                onChange={(e) => handleChange(e, setRecipientInfo)}
                required
                className="payment-input"
              />
              <label className="payment-label">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={recipientInfo.email}
                maxLength="32"
                onChange={(e) => handleChange(e, setRecipientInfo)}
                required
                className="payment-input"
              />
              <label className="payment-label">Delivery Address</label>
              <input
                type="text"
                name="address"
                placeholder="Delivery Address"
                value={recipientInfo.address}
                maxLength="25"
                onChange={(e) => handleChange(e, setRecipientInfo)}
                required
                className="payment-input"
              />

              <div className="input-row">
                <div className="small-input">
                  <label className="payment-label">Apartment #</label>
                  <input
                    type="text"
                    name="apartmentNumber"
                    placeholder="Apartment #"
                    maxLength="5"
                    value={recipientInfo.apartmentNumber || ''}
                    onChange={(e) => handleChange(e, setRecipientInfo)}
                    className="payment-input"
                  />
                </div>
                <div className="small-input">
                  <label className="payment-label">Buzzer</label>
                  <input
                    type="text"
                    name="buzzer"
                    placeholder="Buzzer"
                    value={recipientInfo.buzzer || ''}
                    maxLength="5"
                    onChange={(e) => handleChange(e, setRecipientInfo)}
                    className="payment-input"
                  />
                </div>
              </div>

              <div className="input-row">
                <div className="small-input">
                  <label className="payment-label">City</label>
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={recipientInfo.city}
                    maxLength="10"
                    onChange={(e) => handleChange(e, setRecipientInfo)}
                    required
                    className="payment-input"
                  />
                </div>
                <div className="small-input">
                  <label className="payment-label">Province</label>
                  <input
                    type="text"
                    name="province"
                    placeholder="Province"
                    value={recipientInfo.province}
                    maxLength="20"
                    onChange={(e) => handleChange(e, setRecipientInfo)}
                    required
                    className="payment-input"
                  />
                </div>
                <div className="small-input">
                  <label className="payment-label">Postal Code</label>
                  <input
                    type="text"
                    name="postalCode"
                    placeholder="Postal Code"
                    value={recipientInfo.postalCode}
                    maxLength="7"
                    onChange={(e) => handleChange(e, setRecipientInfo)}
                    required
                    className="payment-input"
                  />
                </div>
              </div>
            </div>

            <h3>Delivery Information</h3>
            <div className="payment-info-group">
              <label className="payment-label">Delivery Date</label>
              <input
                type="date"
                name="date"
                value={deliveryInfo.date}
                onChange={(e) => handleChange(e, setDeliveryInfo)}
                required
                className="payment-input"
              />
              <label className="payment-label">Delivery Time</label>
              <select
                name="time"
                value={deliveryInfo.time}
                onChange={(e) => handleChange(e, setDeliveryInfo)}
                required
                className="payment-input"
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>

            <h3>Payment Information</h3>
             <div id="card-container"></div>

            <div className="payment-info-group">
              <label className="payment-label">Discount Code</label>
              <input
                type="text"
                name="discountCode"
                placeholder="Enter Discount Code"
                value={discountCode}
                maxLength="15"
                onChange={(e) => setDiscountCode(e.target.value)}
                className="payment-input"
              />
              <button type="button" className="apply-discount-btn" onClick={applyDiscount}>
                Apply Discount
              </button>
            </div>
          </div>

          <div className="payment-right-block">
            <h3>Order Summary</h3>
            {cartItems.length > 0 ? (
              cartItems.map((item, index) => (
                <div key={index} className="cart-item-review">
                  <img src={sanitizeInput(item.image)} alt={sanitizeInput(item.title)} className="cart-item-image" />
                  <p>{sanitizeInput(item.title)} - ${sanitizeInput(item.price)}</p>
                  {item.accessories && item.accessories.length > 0 && (
                    <div>
                      {item.accessories.map((acc, accIndex) => (
                        <div key={accIndex} className="cart-item-review">
                          <img src={sanitizeInput(acc.image)} alt={sanitizeInput(acc.title)} className="cart-item-accessory-image" />
                          <p>{sanitizeInput(acc.title)} (x{acc.quantity}) - ${sanitizeInput(acc.price)}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No items in cart.</p>
            )}
<p><strong>Tax (12%):</strong> ${calculateTax(calculateTotalAmount())}</p>
<p><strong>Delivery Fee:</strong> ${deliveryFee.toFixed(2)}</p>

            <h4>Add a Tip (Optional)</h4>
            <input
              type="number"
              name="tipAmount"
              placeholder="Tip Amount"
              value={tipAmount}
              onChange={(e) => setTipAmount(e.target.value)}
              min="0"
              className="tip-input"
            />

            <p><strong>Total Amount (before discount):</strong> ${calculateTotalAmount()}</p>
            <p><strong>Discount Applied:</strong> ${discountAmount.toFixed(2)}</p>
            <p><strong>Total Amount (after discount):</strong> ${(calculateTotalAmount() - discountAmount).toFixed(2)}</p>

            {/* Legal Disclaimer Section */}
            <label className="legal-disclaimer">
              <input
                type="checkbox"
                checked={legalConsent}
                onChange={() => setLegalConsent((prev) => !prev)}
              />
              I agree to the legal terms and conditions
            </label>
            <div className="legal-terms">
              <p>
                <strong>Legal Disclaimer:</strong> By placing this order, I agree to the following:
              </p>
              <ul>
                <li>All deliveries will be facilitated through our platform, and no direct communication between florists and customers will occur.</li>
                <li>It is the customer's responsibility to ensure the accuracy of the recipient's delivery details.</li>
                <li>Any requests for refunds or changes to the order will be reviewed and processed through our platform. Certain charges may not be refundable.</li>
                <li>All personal information provided will only be used for processing and delivering the order and will not be shared with third parties outside of this context.</li>
                <li>Any disputes arising from the transaction will be handled through our platform and are subject to our dispute resolution process.</li>
                <li>I agree to receive emails for sales and marketing purposes.</li>
              </ul>
            </div>

            <button type="submit" className="payment-btn" disabled={loading}>
              {loading ? 'Processing...' : 'Review Payment'}
            </button>
          </div>
        </form>
      ) : (
        <div className="confirmation-page payment-form">
          <div className="payment-left-block">
            <div className="payment-info-group">
              <h4>Sender Information</h4>
              <p><strong>Name:</strong> {sanitizedSenderInfo.firstName} {sanitizedSenderInfo.lastName}</p>
              <p><strong>Phone:</strong> {sanitizedSenderInfo.phoneNumber}</p>
              <p><strong>Email:</strong> {sanitizedSenderInfo.email}</p>
              {sanitizedSenderInfo.message && (
                <p><strong>Message for Card:</strong> {sanitizedSenderInfo.message}</p>
              )}
              {specialInstructions && (
                <p><strong>Special Instructions:</strong> {specialInstructions}</p>
              )}
            </div>

            <div className="payment-info-group">
              <h4>Recipient Information</h4>
              <p><strong>Name:</strong> {sanitizedRecipientInfo.firstName} {sanitizedRecipientInfo.lastName}</p>
              <p><strong>Phone:</strong> {sanitizedRecipientInfo.phoneNumber}</p>
              <p><strong>Email:</strong> {sanitizedRecipientInfo.email}</p>
              <p><strong>Delivery Address:</strong> {sanitizedRecipientInfo.address}, {sanitizedRecipientInfo.city}, {sanitizedRecipientInfo.province}, {sanitizedRecipientInfo.postalCode}</p>
            </div>

            <div className="payment-info-group">
              <h4>Payment Information</h4>
              <p><strong>Card Number:</strong> **** **** **** {sanitizedPaymentInfo.cardNumber.slice(-4)}</p>
              <p><strong>Expiry Date:</strong> {sanitizedPaymentInfo.expiryDate}</p>
            </div>

            <div className="payment-info-group">
              <h4>Delivery Information</h4>
              <p><strong>Date:</strong> {deliveryInfo.date}</p>
              <p><strong>Time:</strong> {deliveryInfo.time}</p>
            </div>
          </div>

          <div className="payment-right-block">
            <h3>Order Summary</h3>
            {cartItems.length > 0 ? (
              cartItems.map((item, index) => (
                <div key={index} className="cart-item-review">
                  <img src={sanitizeInput(item.image)} alt={sanitizeInput(item.title)} className="cart-item-image" />
                  <p>{sanitizeInput(item.title)} - ${sanitizeInput(item.price)}</p>
                  {item.accessories && item.accessories.length > 0 && (
                    <div>
                      <strong>Accessories:</strong>
                      {item.accessories.map((acc, accIndex) => (
                        <div key={accIndex} className="cart-item-review">
                          <img src={sanitizeInput(acc.image)} alt={sanitizeInput(acc.title)} className="cart-item-accessory-image" />
                          <p>{sanitizeInput(acc.title)} (x{acc.quantity}) - ${sanitizeInput(acc.price)}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No items in cart.</p>
            )}

            <p><strong>Tax (12%):</strong> ${(calculateTotalAmount() * 0.12).toFixed(2)}</p>
            <p><strong>Delivery Fee:</strong> ${deliveryFee.toFixed(2)}</p>
            <p><strong>Tip:</strong> ${parseFloat(tipAmount).toFixed(2)}</p>
            <p><strong>Discount Applied:</strong> ${discountAmount.toFixed(2)}</p>
            <p><strong>Total Amount (after discount):</strong> ${(calculateTotalAmount() - discountAmount).toFixed(2)}</p>

            <button onClick={handleConfirm} className="payment-btn payment-confirm-btn" disabled={loading}>
              {loading ? 'Processing...' : 'Confirm Payment'}
            </button>
            <button onClick={() => setIsConfirming(false)} className="payment-btn payment-edit-btn">
              Edit Information
            </button>
            <button onClick={() => navigate('/')} className="payment-btn payment-home-btn">
              Return Home
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
