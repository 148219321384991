// AdminLogin.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminLogin.css'; // Import CSS for styling
import { validateCredentials } from './Account'; // Import the validation function

const AdminLogin = ({ setAdminLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    // Validate credentials using the function from Account.js
    const matchedAccount = validateCredentials(username.trim(), password.trim());

    if (matchedAccount && matchedAccount.role) {
      if (matchedAccount.role.toLowerCase() === 'admin') {
        setAdminLoggedIn(true);
        localStorage.setItem('isAdminLoggedIn', 'true');
        navigate('/delivery');
        return;
      } else {
        setError('This account does not have admin privileges.');
      }
    } else {
      setError('Invalid username or password. Please try again.');
    }
  };

  const handleBackToHome = () => {
    navigate('/'); // Redirect to the home page
  };

  return (
    <div className="admin-login-page-unique">
      <h2>Admin Login</h2>
      <form onSubmit={handleLogin} className="admin-login-form-unique">
        <div className="form-group-unique">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group-unique">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p className="error-unique">{error}</p>}
        <button type="submit" className="btn-unique">Log In</button>
      </form>

      <button onClick={handleBackToHome} className="btn-unique back-home-btn-unique">Back to Home</button>
    </div>
  );
};

export default AdminLogin;
