import React, { useState, useEffect } from 'react';
import './CRM.css';
import { db } from '../Payment/firebase';
import { collection, query, where, doc, updateDoc, onSnapshot } from 'firebase/firestore';
import validator from 'validator'; // For input sanitization

const CRM = ({ floristProfile }) => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    if (!floristProfile) {
      
      return;
    }

    const fetchOrdersFromFirestore = async () => {
      try {
        const floristUid = validator.escape(floristProfile.uid || '');
        if (!floristUid) {
          console.error('Florist UID is undefined');
          return;
        }
        const ordersRef = collection(db, 'orders');
        const q = query(ordersRef, where('florist.uid', '==', floristUid));

        // Listen for real-time updates on orders
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          if (querySnapshot.empty) {
          setOrders([]);
          } else {
            const fetchedOrders = querySnapshot.docs
              .map((doc) => ({ id: doc.id, ...doc.data() }))
              .filter((order) => {
                const hasFloristUid = order.florist && order.florist.uid === floristUid;
                const hasCartItems = Array.isArray(order.cartItems) && order.cartItems.length > 0;
                const isProperOrder = order.id.startsWith('order_');
                const isNotDeclined = order.acceptanceStatus !== 'Declined';

                return hasFloristUid && hasCartItems && isProperOrder && isNotDeclined;
              });
            setOrders(fetchedOrders);
          }
        });

        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrdersFromFirestore();
  }, [floristProfile]);

  // Function to open the print window with a custom sticker layout
  const handlePrintSticker = (order) => {
    const { recipientInfo, senderInfo } = order;
    const floristName = floristProfile.firstName;
    
    // Use the public URL of your logo image from Firebase Storage
    const logoURL = "https://firebasestorage.googleapis.com/v0/b/miniflora-26d96.appspot.com/o/logo.png?alt=media&token=8525d2a3-f718-4f73-8bff-77a43601f9a8";
  
    const printWindow = window.open('', '_blank', 'width=400,height=400');
    printWindow.document.write(`
      <html>
        <head>
          <style>
            .sticker-container {
              width: 12cm;
              height: 8cm;
              border: 1px solid pink;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              padding: 10px;
              box-sizing: border-box;
              font-family: Arial, sans-serif;
              position: relative;
            }
            .logo {
              position: absolute;
              top: 10px;
              left: 10px;
              width: 100px;
              height: auto;
            }
            .florist-store {
              margin-top: 20px;
              font-size: 16px;
              font-weight: bold;
            }
            .recipient-info {
              margin-top: 20px;
              text-align: center;
              font-size: 14px;
            }
            .message {
              margin-top: auto;
              font-size: 16px;
              text-align: center;
              margin-bottom: 25px;
            }
          </style>
        </head>
        <body>
          <div class="sticker-container">
            <img src="${logoURL}" alt="MiniFlora Logo" class="logo" />
            <div class="florist-store">${validator.escape(floristName)} MINISTORE</div>
            <div class="recipient-info">
              <p>${validator.escape(recipientInfo.firstName)} ${validator.escape(recipientInfo.lastName)}</p>
              <p>${validator.escape(recipientInfo.address)}</p>
            </div>
            <div class="message">${validator.escape(senderInfo.message)}</div>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  

  // Function to decline pending orders if they remain in pending status for more than 2 hours
  useEffect(() => {
    const declinePendingOrders = async () => {
      const currentTime = new Date().getTime();
      const twoHoursInMs = 2 * 60 * 60 * 1000;

      orders.forEach(async (order) => {
        const orderCreatedTime = new Date(order.createdAt).getTime();

        if (order.acceptanceStatus === 'Pending' && currentTime - orderCreatedTime >= twoHoursInMs) {
          const orderDocRef = doc(db, 'orders', order.id);
          await updateDoc(orderDocRef, { acceptanceStatus: 'Declined' });

          const response = await fetch('http://localhost:3001/notify-order-declined', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              orderId: order.id,
              floristName: validator.escape(floristProfile.accountName),
              adminEmail: 'arthurlin78901@hotmail.com',
            }),
          });
          const notificationResponse = await response.json();

          if (!response.ok) {
            console.error('Error sending decline notification to admin:', notificationResponse);
          }
        }
      });
    };

    if (orders.length > 0) {
      declinePendingOrders();
    }
  }, [orders, floristProfile]);

  // Function to check if a florist is late in preparing an order
  useEffect(() => {
    const checkFloristPreparation = async () => {
      const currentTime = new Date();

      orders.forEach(async (order) => {
        const deliveryTime = new Date(`${order.deliveryInfo?.date} ${order.deliveryInfo?.time === 'AM' ? '10:00' : '18:00'}`);
        const floristDeadline = new Date(deliveryTime.getTime() - 6 * 60 * 60 * 1000);

        if (currentTime >= floristDeadline) {
          if (!order.floristStatus || order.floristStatus !== 'Ready for Pickup') {
            const orderDocRef = doc(db, 'orders', order.id);
            await updateDoc(orderDocRef, { floristStatus: 'Late' });
            

            if (order.floristStatus === 'Late') {
              await updateDoc(orderDocRef, { acceptanceStatus: 'Declined' });

              const response = await fetch('http://localhost:3001/notify-order-declined', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  orderId: order.id,
                  floristName: validator.escape(floristProfile.accountName),
                  adminEmail: 'arthurlin78901@hotmail.com',
                }),
              });

              const notificationResponse = await response.json();
              console.log('Late order notification response:', notificationResponse);

              if (!response.ok) {
                console.error('Error notifying admin about late order:', notificationResponse);
              }

              setOrders((prevOrders) => prevOrders.filter((ord) => ord.id !== order.id));
            }
          }
        }
      });
    };

    if (orders.length > 0) {
      checkFloristPreparation();
    }
  }, [orders]);

  // Decline an order manually
  const handleDeclineOrder = async (orderId) => {
    try {
      const orderDocRef = doc(db, 'orders', orderId);
      await updateDoc(orderDocRef, { acceptanceStatus: 'Declined' });
      setOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderId));
    } catch (error) {
      console.error('Error declining order:', error);
    }
  };

  // Accept an order
  const handleAcceptOrder = async (orderId) => {
    try {
      const orderDocRef = doc(db, 'orders', orderId);
      await updateDoc(orderDocRef, { acceptanceStatus: 'Accepted' });
      const updatedOrders = orders.map((order) =>
        order.id === orderId ? { ...order, acceptanceStatus: 'Accepted' } : order
      );
      setOrders(updatedOrders);
    } catch (error) {
      console.error('Error updating order acceptance status:', error);
    }
  };

  // Mark an order as 'Ready for Pickup'
  const handleMarkAsReadyForPickup = async (orderId) => {
    try {
      const orderDocRef = doc(db, 'orders', orderId);
      await updateDoc(orderDocRef, { floristStatus: 'Ready for Pickup' });
      const updatedOrders = orders.map((order) =>
        order.id === orderId ? { ...order, floristStatus: 'Ready for Pickup' } : order
      );
      setOrders(updatedOrders);
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  // View order details
  const handleViewDetails = (orderId) => {
    const order = orders.find((order) => order.id === orderId);
    setSelectedOrder(order);
  };

  // Close order details
  const handleCloseDetails = () => {
    setSelectedOrder(null);
  };

  // Render items ordered (flowers and accessories) with images
  const renderItemsOrdered = (cartItems) => {
    return cartItems.map((item, index) => (
      <div key={index} className="order-item-container">
        {/* Flower Item */}
        <div className="order-item">
          {item.image && (
            <img src={item.image} alt={validator.escape(item.title)} className="order-item-thumbnail" />
          )}
          <p>{`Flower: ${validator.escape(item.title)}`} - ${item.price}</p>
        </div>

        {/* Accessories Item */}
        {item.accessories && item.accessories.length > 0 && (
          <div className="accessory-list">
            {item.accessories.map((accessory, accessoryIndex) => (
              <div key={accessoryIndex} className="order-item accessory-item">
                {accessory.image && (
                  <img src={accessory.image} alt={validator.escape(accessory.title)} className="accessory-item-thumbnail" />
                )}
                <p>{`Accessory: ${validator.escape(accessory.title)}`} - ${accessory.price}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="crm-page-unique">
      <h2>Customer Relationship Management (CRM)</h2>

      {orders.length > 0 ? (
        <table className="crm-table-unique">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Sender Name</th>
              <th>Recipient Name</th>
              <th>Delivery Date</th>
              <th>Florist Status</th>
              <th>Delivery Status</th>
              <th>Delivery Photo</th>
              <th>Florist Response</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr key={index}>
                <td>{order.id}</td>
                <td>{`${validator.escape(order.senderInfo?.firstName || 'Unknown')} ${validator.escape(order.senderInfo?.lastName || '')}`}</td>
                <td>{`${validator.escape(order.recipientInfo?.firstName || 'Unknown')} ${validator.escape(order.recipientInfo?.lastName || '')}`}</td>
                <td>{`${validator.escape(order.deliveryInfo?.date || 'N/A')} at ${validator.escape(order.deliveryInfo?.time || 'N/A')}`}</td>
                <td>{validator.escape(order.floristStatus || 'Pending')}</td>
                <td>{order.status === 'Delivered' ? 'Delivered' : 'Pending'}</td>
                <td>
                  {order.deliveryImage ? (
                    <img src={order.deliveryImage} alt="Delivery" width="100" />
                  ) : (
                    <p>Delivery photos have not been updated by the Admin yet.</p>
                  )}
                </td>
                <td>
                  {order.acceptanceStatus === 'Pending' ? (
                    <>
                      <button onClick={() => handleAcceptOrder(order.id)} className="view-btn-unique">Accept</button>
                      <button onClick={() => handleDeclineOrder(order.id)} className="toggle-status-btn-unique">Decline</button>
                    </>
                  ) : order.acceptanceStatus === 'Accepted' && order.floristStatus !== 'Ready for Pickup' ? (
                    <>
                      <span>Accepted</span>
                      <button onClick={() => handleMarkAsReadyForPickup(order.id)} className="delivered-btn-unique">
                        Mark as Ready for Pickup
                      </button>
                    </>
                  ) : (
                    <span>{order.floristStatus === 'Ready for Pickup' ? 'Ready for Pickup' : 'Pending'}</span>
                  )}
                </td>
                <td>
                  {selectedOrder && selectedOrder.id === order.id ? (
                    <button onClick={handleCloseDetails} className="hide-btn-unique">Hide Details</button>
                  ) : (
                    <>
                      <button onClick={() => handleViewDetails(order.id)} className="view-btn-unique">View Details</button>
                      <button onClick={() => handlePrintSticker(order)} className="print-sticker-btn-unique">Print Sticker</button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="no-orders-unique">No orders available.</p>
      )}

      {selectedOrder && (
        <div className="order-details-unique">
          <h3>Order Details</h3>
          <p>
            <strong>Sender:</strong> {validator.escape(selectedOrder.senderInfo?.firstName)} {validator.escape(selectedOrder.senderInfo?.lastName)}
          </p>
          <p>
            <strong>Recipient:</strong> {validator.escape(selectedOrder.recipientInfo?.firstName)} {validator.escape(selectedOrder.recipientInfo?.lastName)}
          </p>
          <p>
            <strong>Delivery Address:</strong> {validator.escape(selectedOrder.recipientInfo?.address)}
          </p>
          <p>
            <strong>Delivery Date:</strong> {validator.escape(selectedOrder.deliveryInfo?.date)} at {validator.escape(selectedOrder.deliveryInfo?.time)}
          </p>

          {selectedOrder.senderInfo?.message && (
            <p>
              <strong>Message for Card:</strong> {validator.escape(selectedOrder.senderInfo.message)}
            </p>
          )}

          <h4>Items Ordered (Flowers and Accessories)</h4>
          {selectedOrder.cartItems && selectedOrder.cartItems.length > 0 ? (
            renderItemsOrdered(selectedOrder.cartItems)
          ) : (
            <p>No items ordered.</p>
          )}

          {selectedOrder.deliveryImage ? (
            <div>
              <h4>Delivery Image:</h4>
              <img src={selectedOrder.deliveryImage} alt="Delivery" width="200" />
            </div>
          ) : (
            <p>No photo yet</p>
          )}

          <button onClick={handleCloseDetails} className="close-btn-unique">
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default CRM;
