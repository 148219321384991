import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../Payment/firebase';
import './SupplierProduct.css';

const SupplierProduct = () => {
  const { supplierId } = useParams(); // Get supplier document ID from URL parameters
  const navigate = useNavigate(); // Define navigate using useNavigate hook
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSupplierUidAndProducts = async () => {

      try {
        // Step 1: Fetch the supplier document to get the uid
        const supplierRef = collection(db, 'suppliers');
        const supplierQuery = query(supplierRef, where('__name__', '==', supplierId)); // Using the document ID to fetch the supplier
        const supplierSnapshot = await getDocs(supplierQuery);

        if (!supplierSnapshot.empty) {
          const supplierData = supplierSnapshot.docs[0].data();
          const supplierUid = supplierData.uid;

          // Step 2: Fetch the products using the supplierUid

          const productsRef = collection(db, 'SupplierProducts'); // Ensure collection name matches Firestore
          const productsQuery = query(productsRef, where('supplierId', '==', supplierUid));
          const productsSnapshot = await getDocs(productsQuery);

          if (!productsSnapshot.empty) {
            const fetchedProducts = productsSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setProducts(fetchedProducts);
          } else {
            console.error('No documents found in the supplierProducts collection for this supplierId.');
            setProducts([]);
          }
        } else {
          console.error('No supplier found for this document ID.');
          setError('No supplier found for this document ID.');
        }
      } catch (err) {
        console.error('Error fetching supplier UID or products:', err);
        setError('Failed to load products. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchSupplierUidAndProducts();
  }, [supplierId]);

  if (loading) {
    return <p>Loading products...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="supplier-product-container">
      <h1>Supplier Products</h1>
      <button onClick={() => navigate(-1)} className="back-button">Return to Previous Page</button>
      {products.length > 0 ? (
        <div className="products-list">
          {products.map((product) => (
            <div key={product.id} className="product-card">
              <img src={product.image} alt={product.productName} className="product-image" />
              <div className="product-details">
                <h3>{product.productName}</h3>
                <p>Price: ${product.price}</p>
                <p>Stock: {product.stock}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No products available for this supplier.</p>
      )}
    </div>
    
  );
};

export default SupplierProduct;
