import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from './Payment/firebase';
import './FloristManagement.css';
import EmptyProfilePic from './EmptyProfile.jpg';

const FloristManagement = () => {
  const [florists, setFlorists] = useState([]);
  const [suppliers, setSuppliers] = useState([]); // New state for suppliers
  const [editingFlorist, setEditingFlorist] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [announcement, setAnnouncement] = useState('');
  const [showAnnouncementInput, setShowAnnouncementInput] = useState(false);

  // Fetching Florist Data
  useEffect(() => {
    const fetchFlorists = async () => {
      const floristCollection = collection(db, 'floristProfiles');
      const floristSnapshot = await getDocs(floristCollection);
      const floristsData = floristSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setFlorists(floristsData);
    };
    fetchFlorists();
  }, []);

  // Fetching Supplier Data
  useEffect(() => {
    const fetchSuppliers = async () => {
      const supplierCollection = collection(db, 'suppliers');
      const supplierSnapshot = await getDocs(supplierCollection);
      const suppliersData = supplierSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setSuppliers(suppliersData);
    };
    fetchSuppliers();
  }, []);

  // Reuse similar logic for handling suppliers like handling florists
  const handleDeleteSupplier = async (supplierId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this supplier account?');
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'suppliers', supplierId));
        setSuppliers(suppliers.filter((supplier) => supplier.id !== supplierId));
      } catch (error) {
        console.error('Error deleting supplier:', error);
      }
    }
  };

  const handleApproveSupplier = async (supplierId, supplierEmail, companyName) => {
    try {
      const supplierRef = doc(db, 'suppliers', supplierId);
      await updateDoc(supplierRef, { status: 'approved' });
      setSuppliers(suppliers.map((supplier) => (supplier.id === supplierId ? { ...supplier, status: 'approved' } : supplier)));
      // Send approval email (can reuse logic from florist approval email)
    } catch (error) {
      console.error('Error approving supplier:', error);
    }
  };

  const handleDeclineSupplier = async (supplierId) => {
    try {
      const supplierRef = doc(db, 'suppliers', supplierId);
      await updateDoc(supplierRef, { status: 'declined' });
      setSuppliers(suppliers.map((supplier) => (supplier.id === supplierId ? { ...supplier, status: 'declined' } : supplier)));
    } catch (error) {
      console.error('Error declining supplier:', error);
    }
  };

  const handleDeleteFlorist = async (floristId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this florist account?');
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'floristProfiles', floristId));
        setFlorists(florists.filter((florist) => florist.id !== floristId));
      } catch (error) {
        console.error('Error deleting florist:', error);
      }
    }
  };

  const handleBanFlorist = async (floristId) => {
    try {
      const floristRef = doc(db, 'floristProfiles', floristId);
      await updateDoc(floristRef, { status: 'ban' });
      setFlorists(florists.map((florist) => (florist.id === floristId ? { ...florist, status: 'ban' } : florist)));
    } catch (error) {
      console.error('Error banning florist:', error);
    }
  };

  const handleUnbanFlorist = async (floristId) => {
    try {
      const floristRef = doc(db, 'floristProfiles', floristId);
      await updateDoc(floristRef, { status: 'normal' });
      setFlorists(florists.map((florist) => (florist.id === floristId ? { ...florist, status: 'normal' } : florist)));
    } catch (error) {
      console.error('Error unbanning florist:', error);
    }
  };

  const handleEditPassword = (florist) => {
    setEditingFlorist(florist);
    setNewPassword(florist.password);
  };

  const handleSavePassword = async () => {
    try {
      const floristRef = doc(db, 'floristProfiles', editingFlorist.id);
      await updateDoc(floristRef, { password: newPassword });
      setFlorists(florists.map((florist) =>
        florist.id === editingFlorist.id ? { ...florist, password: newPassword } : florist
      ));
      setEditingFlorist(null);
      setNewPassword('');
    } catch (error) {
      console.error('Error updating password:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingFlorist(null);
    setNewPassword('');
  };

  // Email notification for florist approval
  const sendApprovalEmail = async (floristEmail, firstName) => {
    try {
      await fetch('http://localhost:3001/notify-florist-approval', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          floristEmail,
          firstName,
        }),
      });
      console.log('Approval email sent successfully.');
    } catch (error) {
      console.error('Error sending approval email:', error);
    }
  };

  const handleApproveFlorist = async (floristId, floristEmail, firstName) => {
    try {
      const floristRef = doc(db, 'floristProfiles', floristId);
      await updateDoc(floristRef, { status: 'normal' });
      setFlorists(florists.map((florist) => (florist.id === floristId ? { ...florist, status: 'normal' } : florist)));
      await sendApprovalEmail(floristEmail, firstName);
    } catch (error) {
      console.error('Error approving florist:', error);
    }
  };

  const handleDeclineFlorist = async (floristId) => {
    try {
      const floristRef = doc(db, 'floristProfiles', floristId);
      await updateDoc(floristRef, { status: 'declined' });
      setFlorists(florists.map((florist) => (florist.id === floristId ? { ...florist, status: 'declined' } : florist)));
    } catch (error) {
      console.error('Error declining florist:', error);
    }
  };

  const handleAnnouncement = async () => {
    if (!announcement.trim()) return;
    try {
      const floristsCollection = collection(db, 'floristProfiles');
      const floristsSnapshot = await getDocs(floristsCollection);
      const announcementPromises = floristsSnapshot.docs.map((floristDoc) =>
        updateDoc(doc(db, 'floristProfiles', floristDoc.id), { announcement })
      );

      await Promise.all(announcementPromises);
      console.log('Announcement saved to Firestore for all florists:', announcement);
      setShowAnnouncementInput(false);
      setAnnouncement('');
    } catch (error) {
      console.error('Error sending announcement:', error);
    }
  };

  const handleCancelAnnouncement = () => {
    setShowAnnouncementInput(false);
    setAnnouncement('');
  };

  return (
    <div className="florist-management-page">
      <h2 className="florist-management-header">Florist Management</h2>
      <button onClick={() => setShowAnnouncementInput(!showAnnouncementInput)} className="btn">
        {showAnnouncementInput ? 'Cancel Announcement' : 'Make Announcement'}
      </button>

      {showAnnouncementInput && (
        <div className="announcement-input">
          <input
            type="text"
            placeholder="Type your announcement here..."
            value={announcement}
            onChange={(e) => setAnnouncement(e.target.value)}
          />
          <button onClick={handleAnnouncement} className="btn">Send</button>
          <button onClick={handleCancelAnnouncement} className="btn">Cancel</button>
        </div>
      )}

      {florists.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Profile Picture</th>
              <th>Florist Name</th>
              <th>Account Name</th>
              <th>Password</th>
              <th>Address</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {florists.map((florist) => (
              <tr key={florist.id}>
                <td>
                  <img
                    src={florist.profilePicture ? florist.profilePicture : EmptyProfilePic}
                    alt={`${florist.firstName} ${florist.lastName}`}
                    className="florist-management-thumbnail"
                  />
                </td>
                <td>{`${florist.firstName} ${florist.lastName}`}</td>
                <td>{florist.accountName}</td>
                <td>
                  {editingFlorist && editingFlorist.id === florist.id ? (
                    <input
                      type="text"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  ) : (
                    florist.password
                  )}
                </td>
                <td>{florist.address}</td>
                <td>{florist.phoneNumber}</td>
                <td>{florist.email}</td>
                <td>{florist.status === 'ban' ? 'Banned' : florist.status.charAt(0).toUpperCase() + florist.status.slice(1)}</td>
                <td>
                  {editingFlorist && editingFlorist.id === florist.id ? (
                    <>
                      <button onClick={handleSavePassword} className="btn">Save</button>
                      <button onClick={handleCancelEdit} className="btn">Cancel</button>
                    </>
                  ) : (
                    <>
                      <button onClick={() => handleEditPassword(florist)} className="btn">Edit Password</button>
                      <button onClick={() => handleDeleteFlorist(florist.id)} className="btn">Delete</button>
                      {florist.status === 'ban' ? (
                        <button onClick={() => handleUnbanFlorist(florist.id)} className="btn">Unban</button>
                      ) : florist.status === 'normal' ? (
                        <>
                          {/* Hide Approve/Decline Buttons */}
                        </>
                      ) : florist.status === 'declined' ? (
                        <p>Declined</p>
                      ) : (
                        <>
                          <button onClick={() => handleApproveFlorist(florist.id, florist.email, florist.firstName)} className="btn">Approve</button>
                          <button onClick={() => handleDeclineFlorist(florist.id)} className="btn">Decline</button>
                        </>
                      )}
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No florists available.</p>
      )}

      <h2 className="supplier-management-header">Supplier Management</h2>

      {/* Supplier Table */}
      {suppliers.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Profile Picture</th>
              <th>Company Name</th>
              <th>Contact Person</th>
              <th>Account Name</th>
              <th>GST Number</th>
              <th>Address</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {suppliers.map((supplier) => (
              <tr key={supplier.id}>
                <td>
                  <img
                    src={supplier.profilePicture ? supplier.profilePicture : EmptyProfilePic}
                    alt={supplier.companyName}
                    className="florist-management-thumbnail"
                  />
                </td>
                <td>{supplier.companyName}</td>
                <td>{supplier.contactPerson}</td>
                <td>{supplier.accountName}</td>
                <td>{supplier.gstNumber}</td>
                <td>{supplier.address}</td>
                <td>{supplier.phoneNumber}</td>
                <td>{supplier.email}</td>
                <td>{supplier.status}</td>
                <td>
                  {supplier.status === 'approved' ? (
                    <button onClick={() => handleDeleteSupplier(supplier.id)} className="btn">Delete</button>
                  ) : (
                    <>
                      <button onClick={() => handleApproveSupplier(supplier.id, supplier.email, supplier.companyName)} className="btn">Approve</button>
                      <button onClick={() => handleDeclineSupplier(supplier.id)} className="btn">Decline</button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No suppliers available.</p>
      )}
    </div>
  );
};

export default FloristManagement;
