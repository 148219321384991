import React, { useEffect, useState } from 'react';
import { auth, db } from '../Payment/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './SupplierCRM.css';

const SupplierCRM = () => {
  const [inquiries, setInquiries] = useState([]);
  const [supplier, setSupplier] = useState(null);

  useEffect(() => {
    const fetchInquiries = async () => {
      const user = auth.currentUser;
      if (user) {
        const supplierRef = collection(db, 'suppliers');
        const supplierQuery = query(supplierRef, where('uid', '==', user.uid));
        const supplierSnapshot = await getDocs(supplierQuery);
        if (!supplierSnapshot.empty) {
          const supplierData = supplierSnapshot.docs[0].data();
          setSupplier(supplierData);

          // Fetch inquiries
          const inquiriesRef = collection(db, 'inquiries');
          const inquiriesQuery = query(inquiriesRef, where('supplierId', '==', user.uid));
          const inquiriesSnapshot = await getDocs(inquiriesQuery);
          setInquiries(inquiriesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        }
      } else {
        // Redirect to login if not authenticated
        window.location.href = '/supplier/login';
      }
    };

    fetchInquiries();
  }, []);

  return (
    <div className="supplier-crm">
      <h1>Supplier CRM</h1>
      {inquiries.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Inquiry ID</th>
              <th>Customer Name</th>
              <th>Message</th>
              <th>Date Received</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {inquiries.map((inquiry) => (
              <tr key={inquiry.id}>
                <td>{inquiry.id}</td>
                <td>{inquiry.customerName}</td>
                <td>{inquiry.message}</td>
                <td>{new Date(inquiry.dateReceived.seconds * 1000).toLocaleDateString()}</td>
                <td>
                  <button onClick={() => alert('Reply functionality not implemented yet.')}>Reply</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No customer inquiries at this time.</p>
      )}
    </div>
  );
};

export default SupplierCRM;
