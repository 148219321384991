import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../Payment/firebase';
import { addDoc, collection } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid'; // For generating unique UID
import './SupplierSignUp.css';

const SupplierSignUp = () => {
  const [companyName, setCompanyName] = useState('');
  const [accountName, setAccountName] = useState(''); // New account name state
  const [gstNumber, setGstNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!hasAgreed) {
      setError('You must agree to the terms before signing up.');
      return;
    }

    setLoading(true);

    try {
      const generatedUID = uuidv4(); // Generate a new UID for the supplier

      const newSupplier = {
        companyName: companyName.trim(),
        accountName: accountName.trim(), // Store account name
        gstNumber,
        contactPerson,
        phoneNumber,
        address,
        email,
        profilePicture: '',
        password,
        status: 'pending',
        uid: generatedUID, // Add UID to new supplier
      };

      let profilePictureURL = '';
if (profilePicture) {
  const pictureRef = ref(storage, `profilePictures/${companyName}-${profilePicture.name}`);
  await uploadBytes(pictureRef, profilePicture);
  profilePictureURL = await getDownloadURL(pictureRef);
  newSupplier.profilePicture = profilePictureURL;
} else {
  // Use the default logo URL if no profile picture is uploaded
  profilePictureURL = "https://firebasestorage.googleapis.com/v0/b/miniflora-26d96.appspot.com/o/EmptyProfile.jpg?alt=media&token=dee376e1-21b7-4c43-9bad-b1611413c55b";
  newSupplier.profilePicture = profilePictureURL;
}

      await addDoc(collection(db, 'suppliers'), newSupplier);

      // Send the email notification to admin
      await fetch('http://localhost:3001/notify-admin-new-supplier', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email, // New supplier's email
          companyName,
        }),
      });

      alert('Sign-up request submitted. Admin will review your request and notify you.');
      setLoading(false);
      navigate('/');
    } catch (error) {
      console.error('Error during sign up:', error);
      setError('Failed to submit sign-up request. Please try again.');
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicture(e.target.files[0]);
    }
  };

  const handleHomeRedirect = () => {
    navigate('/');
  };

  return (
    <div className="supplier-signup-page">
      <h2>Supplier Sign Up</h2>
      <form onSubmit={handleSignUp} className="supplier-signup-form">
        {/* Left side of the form */}
        <div className="supplier-signup-left">
          <input
            type="text"
            placeholder="Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Contact Person"
            value={contactPerson}
            onChange={(e) => setContactPerson(e.target.value)}
            required
          />
          <input
            type="tel"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        {/* Right side of the form */}
        <div className="supplier-signup-right">
          <input
            type="text"
            placeholder="GST Number"
            value={gstNumber}
            onChange={(e) => setGstNumber(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Account Name"
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          {/* File upload */}
          <label className="file-upload-label">Profile Picture:</label>
          <input type="file" accept="image/*" onChange={handleFileChange} className="file-upload-input" />

          <div className="legal-disclaimer">
            <input
              type="checkbox"
              id="agreeTerms"
              checked={hasAgreed}
              onChange={(e) => setHasAgreed(e.target.checked)}
            />
            <label htmlFor="agreeTerms" className="disclaimer-text">
              I agree to the <strong>Legal Disclaimer:</strong>
              <ul>
                <li>We will handle the payments and transfer them to you on daily basis</li>
                <li>You are responsible for product quality and have them ready for delivery or pick up</li>
                <li>MiniFlora reserves the right to terminate accounts for policy violations.</li>
              </ul>
            </label>
          </div>

          {error && <p className="error">{error}</p>}

          <button type="submit" className="signup-btn" disabled={loading}>
            {loading ? 'Submitting...' : 'Sign Up'}
          </button>
        </div>
      </form>

      <button onClick={handleHomeRedirect} className="signup-home-btn">
        Return to Home
      </button>
    </div>
  );
};

export default SupplierSignUp;
