import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import DataLabels plugin
import { db } from '../Payment/firebase'; // Adjust the path according to your folder structure
import './Accounting.css';

// Register Chart.js elements and the DataLabels plugin
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export const PLATFORM_FEE_RATE = 0.1; // 10% platform fee
const GST_RATE = 0.05; // 5% GST

const Accounting = () => {
  const [reportData, setReportData] = useState({
    totalSales: 0,
    totalFees: 0,
    totalDeliveryFee: 0,
    totalFlowerItems: 0,
    totalAccessoryItems: 0,
    avgFlowerPrice: 0,
    avgAccessoryPrice: 0,
  });
  const [timeframe, setTimeframe] = useState('day'); // Default to daily report
  const [feeRate, setFeeRate] = useState(PLATFORM_FEE_RATE); // 10% initial platform fee
  const [isLocked, setIsLocked] = useState(true); // Initial lock state to prevent accidental changes
  const [previousOrders, setPreviousOrders] = useState([]); // New state to store completed orders
  const [cityData, setCityData] = useState({ labels: [], datasets: [] }); // State for pie chart
  const [accountPayable, setAccountPayable] = useState([]); // New state for account payable

  useEffect(() => {
    const fetchSalesData = async () => {
      let totalSales = 0;
      let totalFees = 0;
      let totalDeliveryFee = 0;
      let totalFlowerItems = 0;
      let totalAccessoryItems = 0;
      let flowerPrices = [];
      let accessoryPrices = [];
      let cityCount = {}; // Object to store city counts for pie chart
      let payableData = {}; // Object to store payable data for each florist

      try {
        const salesCollection = collection(db, 'sales');
        const salesSnapshot = await getDocs(salesCollection);
        const sales = salesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        const now = new Date(); // Get the current date
        const completedOrders = [];

        sales.forEach((sale) => {
          // Ensure necessary data exists
          if (!sale.florist || !sale.cartItems) {
            console.warn('Sale missing florist or cartItems:', sale);
            return; // Skip sales without necessary data
          }

          const saleDate = new Date(sale.dateDelivered.seconds * 1000); // Firestore timestamp to JS Date
          const deliveryFee = parseFloat(sale.deliveryFee || 0);
          const totalAmount = parseFloat(sale.totalAmount || 0);
          const saleTax = sale.tax || totalAmount * 0.12;
          const saleFeeRate = sale.platformFeeRate || feeRate;
          
          // Adjust date filtering logic for the current day, month, or year
          const isSameDay = saleDate.toDateString() === now.toDateString();
          const isSameMonth =
            saleDate.getMonth() === now.getMonth() && saleDate.getFullYear() === now.getFullYear();
          const isSameYear = saleDate.getFullYear() === now.getFullYear();

          if (
            (timeframe === 'day' && isSameDay) ||
            (timeframe === 'month' && isSameMonth) ||
            (timeframe === 'year' && isSameYear)
          ) {
            totalSales += totalAmount;
            totalDeliveryFee += deliveryFee;
            totalFees += totalAmount * feeRate;

            sale.cartItems.forEach((item) => {
              if (item.type === 'flower') {
                totalFlowerItems++;
                flowerPrices.push(parseFloat(item.price));
              }
              if (item.accessories && Array.isArray(item.accessories)) {
                item.accessories.forEach((accessory) => {
                  if (accessory.type === 'accessory') {
                    totalAccessoryItems++;
                    accessoryPrices.push(parseFloat(accessory.price));
                  }
                });
              }
            });

            // Calculate account payable for each florist
            const floristId = sale.florist?.uid;
            const floristName = sale.florist?.accountName || sale.florist?.name || 'Unknown Florist'; // Fallback to florist name
            if (floristId) {
              const cartItemsTotal = sale.cartItems.reduce(
                (acc, item) => acc + parseFloat(item.price || 0),
                0
              );
              const floristGST = cartItemsTotal * GST_RATE;
              const platformFee = cartItemsTotal * PLATFORM_FEE_RATE;
              const payable = cartItemsTotal + floristGST - platformFee;

              if (payableData[floristId]) {
                payableData[floristId].total += payable;
              } else {
                payableData[floristId] = {
                  floristName,
                  total: payable,
                };
              }
            }
          }

          // Count cities for pie chart
          const recipientCity = sale.recipientInfo.city;
          if (cityCount[recipientCity]) {
            cityCount[recipientCity]++;
          } else {
            cityCount[recipientCity] = 1;
          }

          // Extract completed orders
          if (sale.status === 'Completed') {
            completedOrders.push(sale);
          }
        });

        const avgFlowerPrice =
          flowerPrices.length > 0 ? flowerPrices.reduce((acc, curr) => acc + curr, 0) / flowerPrices.length : 0;
        const avgAccessoryPrice =
          accessoryPrices.length > 0 ? accessoryPrices.reduce((acc, curr) => acc + curr, 0) / accessoryPrices.length : 0;

        setReportData({
          totalSales: totalSales.toFixed(2),
          totalFees: totalFees.toFixed(2),
          totalDeliveryFee: totalDeliveryFee.toFixed(2),
          totalFlowerItems,
          totalAccessoryItems,
          avgFlowerPrice: avgFlowerPrice.toFixed(2),
          avgAccessoryPrice: avgAccessoryPrice.toFixed(2),
        });

        setPreviousOrders(completedOrders); // Save completed orders in state

        // Prepare pie chart data
        const cityLabels = Object.keys(cityCount);
        const cityCounts = Object.values(cityCount);
        setCityData({
          labels: cityLabels,
          datasets: [
            {
              data: cityCounts,
              backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#4BC0C0',
                '#9966FF',
                '#FF9F40',
              ],
              hoverOffset: 4,
            },
          ],
        });

        // Update account payable state, regardless of timeframe
        console.log('Payable Data:', payableData); // Log the payable data for debugging
        setAccountPayable(Object.values(payableData));

      } catch (error) {
        console.error('Error fetching sales data:', error);
      }
    };

    fetchSalesData();
  }, [timeframe, feeRate]);

  const handleFeeRateChange = () => {
    if (!isLocked) {
      const newRate = parseFloat(prompt('Enter new platform fee rate (e.g., 0.15 for 15%):'));
      if (!isNaN(newRate) && newRate >= 0 && newRate <= 1) {
        setFeeRate(newRate);
      } else {
        alert('Please enter a valid rate between 0 and 1');
      }
    }
  };

  const toggleLock = () => setIsLocked(!isLocked);

  return (
    <div className="accounting">
      <h1>Accounting Page</h1>

      <div className="timeframe-toggle">
        <button onClick={() => setTimeframe('day')} className={timeframe === 'day' ? 'active' : ''}>Day</button>
        <button onClick={() => setTimeframe('month')} className={timeframe === 'month' ? 'active' : ''}>Month</button>
        <button onClick={() => setTimeframe('year')} className={timeframe === 'year' ? 'active' : ''}>Year</button>
      </div>

      <div className="report-container">
        <div className="report-section">
          <h2>{`Report for ${timeframe.charAt(0).toUpperCase() + timeframe.slice(1)}`}</h2>
          <p><strong>Total Sales:</strong> ${reportData.totalSales}</p>
          <p><strong>Total Fees ({(feeRate * 100).toFixed(0)}%):</strong> ${reportData.totalFees}</p>
          <p><strong>Total Delivery Fee:</strong> ${reportData.totalDeliveryFee}</p>
          <p><strong>Total Flower Items Delivered:</strong> {reportData.totalFlowerItems}</p>
          <p><strong>Total Accessory Items Delivered:</strong> {reportData.totalAccessoryItems}</p>
          <p><strong>Average Flower Item Price:</strong> ${reportData.avgFlowerPrice}</p>
          <p><strong>Average Accessory Item Price:</strong> ${reportData.avgAccessoryPrice}</p>
        </div>

        <div className="pie-chart-container">
          <Pie 
            data={cityData} 
            options={{
              plugins: {
                datalabels: {
                  formatter: (value, ctx) => {
                    const total = ctx.chart.data.datasets[0].data.reduce((acc, curr) => acc + curr, 0);
                    const percentage = ((value / total) * 100).toFixed(2);
                    return `${percentage}%`;
                  },
                  color: '#fff',
                  font: {
                    weight: 'bold'
                  }
                },
                tooltip: {
                  callbacks: {
                    label: function (tooltipItem) {
                      const dataset = tooltipItem.dataset;
                      const total = dataset.data.reduce((acc, curr) => acc + curr, 0);
                      const currentValue = dataset.data[tooltipItem.dataIndex];
                      const percentage = ((currentValue / total) * 100).toFixed(2);
                      return `${tooltipItem.label}: ${percentage}%`;
                    }
                  }
                }
              }
            }} 
          />
        </div>

        {/* Account Payable Section (Fixed for monthly payments) */}
        <div className="account-payable-section">
          <h2>Account Payable (Monthly)</h2>
          {accountPayable.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Florist</th>
                  <th>Account Payable</th>
                </tr>
              </thead>
              <tbody>
                {accountPayable.map((payable, index) => (
                  <tr key={index}>
                    <td>{payable.floristName}</td>
                    <td>${payable.total.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No data available for account payable.</p>
          )}
        </div>
      </div>

      <div className="fee-controls">
        <button onClick={handleFeeRateChange} disabled={isLocked}>Set Platform Fee</button>
        <button onClick={toggleLock}>{isLocked ? 'Unlock' : 'Lock'}</button>
      </div>

      <h2>Previous Orders</h2>
      {previousOrders.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Recipient</th>
              <th>Total Amount</th>
              <th>Delivery Fee</th>
              <th>Date Delivered</th>
            </tr>
          </thead>
          <tbody>
            {previousOrders.map((order, index) => (
              <tr key={index}>
                <td>{order.id}</td>
                <td>{`${order.recipientInfo.firstName} ${order.recipientInfo.lastName}`}</td>
                <td>${order.totalAmount}</td>
                <td>${order.deliveryFee}</td>
                <td>{new Date(order.dateDelivered.seconds * 1000).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No previous orders available.</p>
      )}
    </div>
  );
};

export default Accounting;
