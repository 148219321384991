import React, { useEffect, useState } from 'react';
import { auth, db, storage } from '../Payment/firebase';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './SupplierStore.css';

const SupplierStore = () => {
  const [supplier, setSupplier] = useState(null);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [newProduct, setNewProduct] = useState({
    productName: '',
    price: '',
    stock: '',
    image: '',
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);

  // Fetch supplier data using the Firestore "uid" field inside the document
  useEffect(() => {
    const fetchSupplierData = async () => {
      const user = auth.currentUser;

      if (user) {
        try {
          const supplierRef = collection(db, 'suppliers');
          const q = query(supplierRef, where('uid', '==', 'ec73019f-f3e0-4d84-b4fe-ca42af507917'));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const supplierData = querySnapshot.docs[0].data();
            setSupplier(supplierData);
           
          } else {
            console.error(`No supplier found with UID: ec73019f-f3e0-4d84-b4fe-ca42af507917`);
            setError('No supplier found for this user.');
          }
        } catch (err) {
          console.error('Error fetching supplier data:', err);
          setError('Failed to load supplier data. Please try again later.');
        }
      } else {
        console.error('User is not authenticated. Redirecting to login.');
        window.location.href = '/supplier/login';
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchSupplierData();
      }
    });
  }, []);

  // Fetch products and orders when supplier is loaded
  useEffect(() => {
    if (supplier) {
      const fetchData = async () => {
        const productsRef = collection(db, 'SupplierProducts');
        const productsQuery = query(productsRef, where('supplierId', '==', supplier.uid));
        const productsSnapshot = await getDocs(productsQuery);
        setProducts(productsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));

        const ordersRef = collection(db, 'orders');
        const ordersQuery = query(ordersRef, where('supplierId', '==', supplier.uid));
        const ordersSnapshot = await getDocs(ordersQuery);
        setOrders(ordersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      };

      fetchData();
    }
  }, [supplier]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && supplier) {
      const storageRef = ref(storage, `productImages/${supplier.uid}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error('Error uploading image:', error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setNewProduct((prevProduct) => ({
            ...prevProduct,
            image: downloadURL,
          }));
        }
      );
    }
  };

  // Handle adding new product
const handleAddProduct = async (e) => {
  e.preventDefault();
  if (newProduct.productName && newProduct.price && newProduct.stock && newProduct.image && supplier) {
      try {
          const productId = `${supplier.uid}-${new Date().getTime()}`; // Unique product ID
          const productData = { ...newProduct, supplierId: supplier.uid };
          const productRef = doc(db, 'SupplierProducts', productId);
          
          // Attempt to write the product data to Firestore
          await setDoc(productRef, productData);

          // Update the local state to reflect new product
          setProducts((prevProducts) => [...prevProducts, { ...productData, id: productId }]);
          
          // Reset form
          setNewProduct({ productName: '', price: '', stock: '', image: '' });
          alert('Product added successfully!');

      } catch (error) {
          console.error('Error adding product to Firestore:', error);
          alert('Error adding product. Please try again.');
      }
  } else {
      alert('Please fill out all fields and upload an image.');
  }
};


  const handleEditProduct = (productId) => {
    window.location.href = `/supplier/edit-product/${productId}`;
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await deleteDoc(doc(db, 'SupplierProducts', productId));
      setProducts(products.filter((product) => product.id !== productId));
      alert('Product deleted successfully.');
    } catch (error) {
      console.error('Error deleting product:', error);
      alert('Error deleting product. Please try again.');
    }
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        window.location.href = '/supplier/login';
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  return (
    <div className="supplier-store-container">
      <h1 className="supplier-store-header">
        {supplier ? `Welcome, ${supplier.companyName}` : error || 'Supplier data not loaded. Please contact support.'}
      </h1>

      {supplier && (
        <>
          <button className="supplier-store-button" onClick={handleLogout}>
            Logout
          </button>

          <section className="products-section">
            <h2>Your Products</h2>

            <form className="supplier-store-form" onSubmit={handleAddProduct}>
              <div className="supplier-store-form-group">
                <label htmlFor="productName">Product Name</label>
                <input
                  type="text"
                  id="productName"
                  name="productName"
                  value={newProduct.productName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="supplier-store-form-group">
                <label htmlFor="price">Price</label>
                <input
                  type="number"
                  id="price"
                  name="price"
                  value={newProduct.price}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="supplier-store-form-group">
                <label htmlFor="stock">Stock</label>
                <input
                  type="number"
                  id="stock"
                  name="stock"
                  value={newProduct.stock}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="supplier-store-form-group">
                <label htmlFor="image">Upload Image</label>
                <input type="file" id="image" name="image" accept="image/*" onChange={handleImageUpload} required />
                {uploadProgress > 0 && uploadProgress < 100 && <p>Uploading: {Math.round(uploadProgress)}%</p>}
                {newProduct.image && <img src={newProduct.image} alt="Uploaded product" className="product-image-preview" />}
              </div>

              <button type="submit" className="supplier-store-button">
                Add Product
              </button>
            </form>

            {products.length > 0 ? (
  <table className="supplier-store-table">
    <thead>
      <tr>
        <th>Image</th> {/* New column for thumbnails */}
        <th>Product Name</th>
        <th>Price</th>
        <th>Stock</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {products.map((product) => (
        <tr key={product.id}>
          {/* Thumbnail column */}
          <td>
            <img 
              src={product.image} 
              alt={`${product.productName} thumbnail`} 
              className="product-thumbnail" 
            />
          </td>
          <td>{product.productName}</td>
          <td>${product.price}</td>
          <td>{product.stock}</td>
          <td>
            <button
              className="supplier-store-button"
              onClick={() => window.location.href = `/supplier/edit-product/${product.id}`}
            >
              Edit
            </button>
            <button
              className="supplier-store-button"
              onClick={() => handleDeleteProduct(product.id)}
            >
              Delete
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
) : (
  <p>No products found.</p>
)}

          </section>

          <section className="orders-section">
            <h2>Recent Orders</h2>
            {orders.length > 0 ? (
              <table className="supplier-store-table">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Buyer</th>
                    <th>Total Amount</th>
                    <th>Date Ordered</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order.id}>
                      <td>{order.id}</td>
                      <td>{order.buyerName}</td>
                      <td>${order.totalAmount}</td>
                      <td>{new Date(order.dateOrdered.seconds * 1000).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No orders found.</p>
            )}
          </section>
        </>
      )}
    </div>
  );
};

export default SupplierStore;
