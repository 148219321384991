import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'; // No need for BrowserRouter
import { doc, getDoc } from 'firebase/firestore'; // Firestore imports
import Cookies from 'js-cookie'; // For cookie management
import ClientPageApp from './ClientPageApp';
import FloristPageApp from './FloristPageApp';
import LoginPage from './LoginPage';
import AdminLogin from './AdminLogin';
import Delivery from './Delivery/Delivery';
import AccountInformation from './AccountInformation';
import StoreInformation from './StoreInformation';
import StorePage from './StorePage';
import NavbarUnique from './Navbar'; // Ensure this matches your updated component
import PaymentPage from './Payment/Payment';
import CRM from './CRM/CRM';
import SignUpPage from './SignUp';
import FloristManagement from './FloristManagement';
import ChatHistoryPage from './ChatHistoryPage';
import ChatWidget from './ChatWidget';
import SalesAnalysis from './SalesAnalysis';
import Accounting from './Accounting/Accounting'; // Import Accounting component
import { db } from './Payment/firebase';
import validator from 'validator'; // For input sanitization
import CustomerSignUp from './CustomerProfile/CustomerSignUp'; // Import new CustomerProfile component
import CustomerProfile from './CustomerProfile/CustomerProfile'; // Add this line
import CustomerLogIn from './CustomerProfile/CustomerLogIn'; // Adjust path if necessary
import CustomerManagement from './CustomerProfile/CustomerManagement'; // Import CustomerManagement component

// New supplier imports
import SupplierSignUp from './Supplier/SupplierSignUp';
import SupplierLogin from './Supplier/SupplierLogin';
import SupplierStore from './Supplier/SupplierStore'; // Updated import
import SupplierCRM from './Supplier/SupplierCRM';
import SupplierPayment from './Supplier/SupplierPayment';
import SupplierProfiles from './Supplier/SupplierProfiles'; // Import SupplierProfiles component
import SupplierProduct from './Supplier/SupplierProduct'; // Import SupplierProduct component

function App() {
  const navigate = useNavigate(); // Adding useNavigate here

  // Initialize cart state at the top level
  const [cart, setCart] = useState([]); // Cart state for managing items in the cart
  const [totalAmount, setTotalAmount] = useState(0); // Total amount state for cart

  // Initialize floristProfile state from cookies, or fallback to localStorage
  const [floristProfile, setFloristProfile] = useState(() => {
    const savedFloristProfile = Cookies.get('floristProfile') || localStorage.getItem('floristProfile');
    return savedFloristProfile ? JSON.parse(savedFloristProfile) : null;
  });

  // Initialize customerProfile state from cookies, or fallback to localStorage
  const [customerProfile, setCustomerProfile] = useState(() => {
    const savedCustomerProfile = Cookies.get('customerProfile') || localStorage.getItem('customerProfile');
    return savedCustomerProfile ? JSON.parse(savedCustomerProfile) : null;
  });

  const [floristProfiles, setFloristProfiles] = useState([]);
  const [isAdminLoggedIn, setAdminLoggedIn] = useState(() => {
    const savedAdminStatus = Cookies.get('isAdminLoggedIn');
    return savedAdminStatus ? JSON.parse(savedAdminStatus) : false;
  });
// Initialize feeRate state
const [feeRate, setFeeRate] = useState(0.1); // 10% as the default rate

  // ---- New SupplierProfile State ----
  const [supplierProfile, setSupplierProfile] = useState(() => {
    const savedSupplierProfile = Cookies.get('supplierProfile') || localStorage.getItem('supplierProfile');
    return savedSupplierProfile ? JSON.parse(savedSupplierProfile) : null;
  });

  // Save floristProfile to cookies AND localStorage whenever it changes, with an expiry and security options
  useEffect(() => {
    if (floristProfile) {
      const sanitizedFloristProfile = {
        ...floristProfile,
        accountName: validator.escape(floristProfile.accountName),
        email: validator.escape(floristProfile.email),
      };

      // Save to cookies
      Cookies.set('floristProfile', JSON.stringify(sanitizedFloristProfile), {
        secure: true,
        sameSite: 'Strict',
        httpOnly: false, // Remove httpOnly since we need access to the cookie in JavaScript
        expires: 1, // Cookie expires in 1 day
      });

      // Save to localStorage as a fallback
      localStorage.setItem('floristProfile', JSON.stringify(sanitizedFloristProfile));
    } else {
      Cookies.remove('floristProfile');
      localStorage.removeItem('floristProfile');
    }
  }, [floristProfile]);

  // Save customerProfile to cookies AND localStorage whenever it changes, with an expiry and security options
  useEffect(() => {
    if (customerProfile) {
      const sanitizedCustomerProfile = {
        ...customerProfile,
        email: validator.escape(customerProfile.email),
      };

      // Save to cookies
      Cookies.set('customerProfile', JSON.stringify(sanitizedCustomerProfile), {
        secure: true,
        sameSite: 'Strict',
        httpOnly: false,
        expires: 1, // Cookie expires in 1 day
      });

      // Save to localStorage as a fallback
      localStorage.setItem('customerProfile', JSON.stringify(sanitizedCustomerProfile));
    } else {
      Cookies.remove('customerProfile');
      localStorage.removeItem('customerProfile');
    }
  }, [customerProfile]);

  // ---- Save supplierProfile to cookies AND localStorage whenever it changes ----
  useEffect(() => {
    if (supplierProfile) {
      const sanitizedSupplierProfile = {
        ...supplierProfile,
        accountName: validator.escape(supplierProfile.accountName),
        email: validator.escape(supplierProfile.email),
      };

      // Save to cookies
      Cookies.set('supplierProfile', JSON.stringify(sanitizedSupplierProfile), {
        secure: true,
        sameSite: 'Strict',
        httpOnly: false,
        expires: 1, // Cookie expires in 1 day
      });

      // Save to localStorage as a fallback
      localStorage.setItem('supplierProfile', JSON.stringify(sanitizedSupplierProfile));
    } else {
      Cookies.remove('supplierProfile');
      localStorage.removeItem('supplierProfile');
    }
  }, [supplierProfile]);

  // Save admin status to cookies whenever it changes, with an expiry and security options
  useEffect(() => {
    Cookies.set('isAdminLoggedIn', JSON.stringify(isAdminLoggedIn), {
      secure: true,
      sameSite: 'Strict',
      httpOnly: false, // Remove httpOnly since we need access to the cookie in JavaScript
      expires: 1, // Cookie expires in 1 day
    });
  }, [isAdminLoggedIn]);

  // Implement session timeout to log out after 1 hour of inactivity
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleLogout(); // Automatically log out after 1 hour
    }, 3600000); // 1 hour in milliseconds

    return () => clearTimeout(timeout); // Clear timeout on component unmount
  }, [floristProfile, customerProfile, supplierProfile]); // Updated dependencies to include supplierProfile

  // Clear state and cookies/localStorage upon logout
  const handleLogout = () => {
    // Clear floristProfile, customerProfile, and supplierProfile from state and cookies/localStorage
    setFloristProfile(null);
    setCustomerProfile(null);
    setSupplierProfile(null); // Clear supplierProfile
    setAdminLoggedIn(false);
    Cookies.remove('floristProfile');
    Cookies.remove('customerProfile');
    Cookies.remove('supplierProfile'); // Remove supplierProfile
    Cookies.remove('isAdminLoggedIn');
    localStorage.removeItem('floristProfile');
    localStorage.removeItem('customerProfile');
    localStorage.removeItem('supplierProfile'); // Remove supplierProfile

    // Use a callback to ensure state is updated before redirecting
    setTimeout(() => {
      navigate('/login'); // Ensure proper redirection on logout after state update
    }, 100); // Small delay to allow state update
  };

  return (
    <div>
      <NavbarUnique
        floristProfile={floristProfile}
        customerProfile={customerProfile} // Pass customer profile
        supplierProfile={supplierProfile} // Pass supplier profile
        setFloristProfile={setFloristProfile}
        setCustomerProfile={setCustomerProfile} // Pass setCustomerProfile
        setSupplierProfile={setSupplierProfile} // Pass setSupplierProfile
        handleLogout={handleLogout}
        isAdminLoggedIn={isAdminLoggedIn}
        cart={cart} // Pass cart to Navbar
        totalAmount={totalAmount} // Pass totalAmount to Navbar
        setCart={setCart} // Pass setCart to Navbar
      />
      <Routes>
        <Route
          path="/"
          element={
            floristProfile ? (
              <Navigate to="/FloristPage" />
            ) : customerProfile ? (
              <Navigate to="/customer-profile" />
            ) : supplierProfile ? ( // New supplier profile check
              <Navigate to="/supplier/store" />
            ) : (
              <ClientPageApp floristProfiles={floristProfiles} cart={cart} setCart={setCart} totalAmount={totalAmount} setTotalAmount={setTotalAmount} />
            )
          }
        />
        <Route path="/signup" element={<SignUpPage setFloristProfile={setFloristProfile} />} />
        <Route
          path="/FloristPage"
          element={
            floristProfile ? (
              <FloristPageApp setFloristProfile={setFloristProfile} floristProfile={floristProfile} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/login"
          element={
            floristProfile ? (
              <Navigate to="/FloristPage" />
            ) : customerProfile ? (
              <Navigate to="/customer-profile" />
            ) : supplierProfile ? ( // New supplier profile check
              <Navigate to="/supplier/store" />
            ) : (
              <LoginPage setFloristProfile={setFloristProfile} />
            )
          }
        />
        <Route
          path="/admin"
          element={
            isAdminLoggedIn ? (
              <Navigate to="/delivery" />
            ) : (
              <AdminLogin setAdminLoggedIn={setAdminLoggedIn} />
            )
          }
        />
        <Route
  path="/delivery"
  element={
    isAdminLoggedIn ? (
      <Delivery floristProfiles={floristProfiles} isAdminLoggedIn={isAdminLoggedIn} feeRate={feeRate} />
    ) : (
      <Navigate to="/admin" />
    )
  }
/>
        <Route
          path="/account"
          element={
            floristProfile ? (
              <AccountInformation floristProfile={floristProfile} setFloristProfile={setFloristProfile} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/store"
          element={
            floristProfile ? (
              <StoreInformation floristProfile={floristProfile} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/store/:accountName" element={<StorePage cart={cart} setCart={setCart} totalAmount={totalAmount} setTotalAmount={setTotalAmount} />} />
        <Route
          path="/payment"
          element={<PaymentPage floristProfile={floristProfile} cart={cart} totalAmount={totalAmount} setCart={setCart} />}
        />
        <Route
          path="/crm"
          element={
            floristProfile ? (
              <CRM floristProfile={floristProfile} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/florist-management" element={isAdminLoggedIn ? <FloristManagement /> : <Navigate to="/admin" />} />
        <Route 
          path="/chat-history" 
          element={isAdminLoggedIn ? <ChatHistoryPage isAdminLoggedIn={isAdminLoggedIn} /> : <Navigate to="/admin" />} 
        />
        <Route
          path="/sales-analysis"
          element={
            floristProfile ? (
              <SalesAnalysis floristProfile={floristProfile} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
  path="/accounting"
  element={
    isAdminLoggedIn ? (
      <Accounting feeRate={feeRate} setFeeRate={setFeeRate} />
    ) : (
      <Navigate to="/admin" />
    )
  }
/>
        <Route 
          path="/customer-signup" 
          element={<CustomerSignUp setCustomerProfile={setCustomerProfile} />} 
        />
        <Route 
          path="/customer-profile" 
          element={customerProfile ? <CustomerProfile userId={customerProfile.uid} /> : <Navigate to="/customer-login" />} 
        />
        <Route 
          path="/customer-login" 
          element={<CustomerLogIn setCustomerProfile={setCustomerProfile} />} 
        />
        <Route 
          path="/customer-management" 
          element={isAdminLoggedIn ? <CustomerManagement /> : <Navigate to="/admin" />} 
        />

        {/* Supplier Routes */}
        <Route path="/supplier/signup" element={<SupplierSignUp />} />
        <Route path="/supplier/login" element={<SupplierLogin setSupplierProfile={setSupplierProfile} />} />
        <Route path="/supplier/store" element={<SupplierStore />} /> {/* Updated route */}
        <Route path="/supplier-crm" element={<SupplierCRM />} />
        <Route path="/supplier-payment" element={<SupplierPayment />} />
        <Route path="/supplier-profiles" element={<SupplierProfiles />} />
        <Route path="/supplier-product/:supplierId" element={<SupplierProduct />} />
      </Routes>

      {floristProfile && <ChatWidget userType="Florist" userName={floristProfile.accountName} chatType="florist-admin" recipient="Admin" />}
      {customerProfile && <ChatWidget userType="Customer" userName={customerProfile.accountName} chatType="customer-admin" recipient="Admin" />}
      {supplierProfile && <ChatWidget userType="Supplier" userName={supplierProfile.accountName} chatType="supplier-admin" recipient="Admin" />}
    </div>
  );
}

export default App;
