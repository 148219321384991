import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../Payment/firebase'; // Adjust import path if needed
import { addDoc, collection } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid'; 
import './CustomerSignUp.css';

const CustomerProfile = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [accountName, setAccountName] = useState('');
  const [password, setPassword] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const generatedUID = uuidv4();
      const newCustomer = {
        firstName,
        lastName,
        email,
        accountName,
        password,
        profilePicture: '',
        uid: generatedUID,
      };

      let profilePictureURL = '';
      if (profilePicture) {
        const pictureRef = ref(storage, `profilePictures/${email}-${profilePicture.name}`);
        await uploadBytes(pictureRef, profilePicture);
        profilePictureURL = await getDownloadURL(pictureRef);
        newCustomer.profilePicture = profilePictureURL;
      }

      await addDoc(collection(db, 'customerProfiles'), newCustomer);

      alert('Account created successfully!');
      setLoading(false);
      navigate('/');
    } catch (error) {
      console.error('Error creating customer profile:', error);
      setError('Failed to create account. Please try again.');
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicture(e.target.files[0]);
    }
  };

  return (
    <div className="customer-profile-page">
      <h2>Create a Customer Account</h2>
      <form onSubmit={handleSignUp} className="customer-profile-form">
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="AccountName"
          placeholder="Account Name"
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <label className="file-upload-label">Profile Picture:</label>
        <input type="file" accept="image/*" onChange={handleFileChange} />

        {error && <p className="error">{error}</p>}

        <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Create Account'}
        </button>
      </form>
    </div>
  );
};

export default CustomerProfile;
