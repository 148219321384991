import React, { useState, useEffect } from 'react';
import './ClientPageApp.css';
import FlowerModal from './FlowerModal';
import { useNavigate } from 'react-router-dom';
import EmptyProfilePic from './EmptyProfile.jpg';
import ChatWidget from './ChatWidget';
import { db } from './Payment/firebase';
import { collection, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import NavbarUnique from './Navbar';

const TAX_RATE = 0.12;

const ClientPageApp = () => {
  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedFlower, setSelectedFlower] = useState(null);
  const [selectedFlorist, setSelectedFlorist] = useState(null);
  const [accessories, setAccessories] = useState([]);
  const [deliveryArea, setDeliveryArea] = useState('');
  const [sortedStores, setSortedStores] = useState([]);
  const [currentPage, setCurrentPage] = useState({});
  const [floristProfile, setFloristProfile] = useState(null);
  const [customerProfile, setCustomerProfile] = useState(null);
  
  const navigate = useNavigate();

  // Define the handleLogout function
  const handleLogout = () => {
    // Add the logout logic here (e.g., clearing localStorage/session)
    console.log('User logged out');
    navigate('/login'); // Adjust the route to where you want to redirect the user
  };

  // Changed to 4 items per page
  const itemsPerPage = 4;

  const safeText = (text) => {
    const div = document.createElement('div');
    div.textContent = text;
    return div.innerHTML;
  };

  useEffect(() => {
    const fetchFloristsAndStores = async () => {
      try {
        const floristCollection = collection(db, 'floristProfiles');
        const floristSnapshot = await getDocs(floristCollection);
        const florists = floristSnapshot.docs.map((doc) => ({
          ...doc.data(),  // This will include uid from the document data
          uid: doc.data().uid,  // Ensuring uid is correctly mapped from the field in the document
        }));
    
        const stores = await fetchStoresForFlorists(florists);
        const sorted = sortStoresByDistance(stores);
        setSortedStores(sorted);
      } catch (error) {
        console.error('Error fetching florists and stores:', error);
        alert('Failed to load stores. Please try again later.');
      }
    };
    
    

const fetchStoresForFlorists = async (florists) => {
  let stores = {};
  for (let florist of florists) {
    try {
      const storeRef = doc(db, 'stores', florist.accountName);
      const storeSnapshot = await getDoc(storeRef);

      if (storeSnapshot.exists()) {
        const storeData = storeSnapshot.data();
        const items = storeData.items || [];
        const isStoreOnline = storeData.isOnline || false;
        const videoURL = storeData.videoURL || ''; // Fetch video URL

        if (items.length > 0 && isStoreOnline && florist.uid) {
          stores[florist.accountName] = {
            items,
            floristProfile: florist,
            videoURL, // Include the video URL in the store object
          };
        }
      } else {
        console.warn(`Store not found for account: ${florist.accountName}`);
      }
    } catch (error) {
      console.error(`Error fetching store for ${florist.accountName}:`, error);
    }
  }
  return stores;
};


    fetchFloristsAndStores();
  }, [deliveryArea]);

  const calculateDistance = (floristLocation, deliveryLocation) => {
    return floristLocation.toLowerCase().includes(deliveryLocation.toLowerCase()) ? 0 : 1;
  };

  const sortStoresByDistance = (stores) => {
    return Object.entries(stores)
      .map(([accountName, store]) => {
        if (!store || !store.floristProfile) {
          console.warn(`Florist profile or store missing for account: ${accountName}`);
          return null;
        }

        const floristProfile = store.floristProfile;
        const distance = deliveryArea.trim()
          ? calculateDistance(floristProfile.address, deliveryArea)
          : 0;

        return { accountName, store, floristProfile, distance };
      })
      .filter(Boolean)
      .sort((a, b) => a.distance - b.distance);
  };

  const handleAddToCart = (flower, selectedAccessories = []) => {
    if (!selectedFlorist || !selectedFlorist.uid) {
      alert('Florist information is missing.');
      return;
    }
  
    if (cart.length > 0 && cart[0].floristProfile.uid !== selectedFlorist.uid) {
      alert('You can only order from one florist at a time. Please complete your current transaction or clear the cart to order from a different florist.');
      return;
    }
  
    const flowerPrice = parseFloat(flower.price);
    if (isNaN(flowerPrice) || flowerPrice <= 0) {
      alert('Invalid flower price.');
      return;
    }
  
    const cartItem = {
      ...flower,
      floristProfile: { // Ensure florist profile info is directly included in cart item
        uid: selectedFlorist.uid,
        accountName: selectedFlorist.accountName,
        address: selectedFlorist.address,
      },
      accessories: selectedAccessories.map((accessory) => ({
        ...accessory,
        quantity: accessory.quantity || 1, // Set default quantity if not specified
      })),
    };
  
    setCart((prevCart) => [...prevCart, cartItem]);
    setTotalAmount((prevTotal) => prevTotal + flowerPrice + selectedAccessories.reduce((total, acc) => total + parseFloat(acc.price), 0));
  
    
};

  

  const handleDeleteFromCart = (index) => {
    const updatedCart = [...cart];
    const removedItem = updatedCart.splice(index, 1)[0];

    const flowerPrice = parseFloat(removedItem.price);
    const accessoriesPrice = removedItem.accessories
      ? removedItem.accessories.reduce((total, accessory) => total + parseFloat(accessory.price), 0)
      : 0;

    setCart(updatedCart);
    setTotalAmount((prevTotal) => prevTotal - (flowerPrice + accessoriesPrice));
  };

  const handleCheckout = () => {
    if (!selectedFlorist || !selectedFlorist.uid) {
      alert('Florist information is missing.');
      return;
    }
  
    navigate('/payment', {
      state: {
        cartItems: cart,
        totalAmount,
        floristUid: selectedFlorist.uid,  // Ensure this is passed correctly
        floristAddress: selectedFlorist.address,
      },
    });
  };
 
  const incrementVisitorCount = async (accountName) => {
    try {
      const storeRef = doc(db, 'stores', accountName);
      const storeSnapshot = await getDoc(storeRef);

      if (storeSnapshot.exists()) {
        const visitorCount = storeSnapshot.data().visitorCount || 0;
        await updateDoc(storeRef, { visitorCount: visitorCount + 1 });
      }
    } catch (error) {
      console.error('Error incrementing visitor count:', error);
    }
  };

  const handleOpenModal = (flower, floristProfile, store) => {
    const availableAccessories = store.items.filter(item => item.type === 'accessory');
    
    setSelectedFlower({
      ...flower,
      title: safeText(flower.title),
      price: safeText(flower.price),
      image: flower.image,
    });

    // Setting selectedFlorist with expected structure
    setSelectedFlorist({
      uid: floristProfile.uid,                 // Ensure uid is directly assigned
      accountName: safeText(floristProfile.accountName), // Sanitize account name
      address: safeText(floristProfile.address),         // Sanitize address
    });
    
    // Log selectedFlorist after setting
      setAccessories(
      availableAccessories.map(acc => ({
        ...acc,
        title: safeText(acc.title),
        price: safeText(acc.price),
      }))
    );
};

  

  const handleStoreClick = (accountName, floristProfile, store) => {
    incrementVisitorCount(accountName);
    navigate(`/store/${accountName}`, { state: { floristProfile, store } });
  };

  const handleCloseModal = () => {
    setSelectedFlower(null);
  };

  const handleDeliveryAreaChange = (e) => {
    const sanitizedInput = e.target.value.replace(/[^\w\s-]/gi, "");
    setDeliveryArea(sanitizedInput);
  };

  // Paginate function to slice the array of flowers based on the current page
  const paginate = (items = [], pageNumber) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  };

 // Function to handle the "Next Page" button click
 const handleNextPage = (shopName) => {
   setCurrentPage((prevPage) => ({
     ...prevPage,
     [shopName]:      (prevPage[shopName] || 1) + 1, // Increment the current page
    }));
  };
 
  // Function to handle the "Previous Page" button click
  const handlePreviousPage = (shopName) => {
    setCurrentPage((prevPage) => ({
      ...prevPage,
      [shopName]: Math.max((prevPage[shopName] || 1) - 1, 1), // Decrement, but don't go below 1
    }));
  };
 
  const taxAmount = totalAmount * TAX_RATE;
  const finalTotal = totalAmount + taxAmount;
 
  return (
    <div className="client-page">
      <NavbarUnique
        cart={cart}
        totalAmount={totalAmount}
        floristProfile={floristProfile}
        customerProfile={customerProfile}
        setFloristProfile={setFloristProfile}
        setCustomerProfile={setCustomerProfile}
        setCart={setCart} // Pass setCart as a prop
        handleLogout={handleLogout} // Added the logout handler
      />
 
      <div className="client-main-content">
        <div className="client-left-column">
          <div className="client-filter-section">
            <input
              type="text"
              id="client-delivery-area"
              value={deliveryArea}
              onChange={handleDeliveryAreaChange}
              placeholder="Enter area for delivery"
              className="client-delivery-area"
            />
          </div>
 
          <div className="client-scrollable-shop-list">
            {sortedStores.reduce((rows, store, index) => {
              if (index % 2 === 0) {
                rows.push([store]);
              } else {
                rows[rows.length - 1].push(store);
              }
              return rows;
            }, []).map((storePair, rowIndex) => (
              <div key={rowIndex} className="client-florist-row">
                {storePair.map(({ accountName, store, floristProfile }, shopIndex) => {
                  const flowers = store.items.filter(item => item.type === 'flower');
                  if (!flowers || flowers.length === 0) return null;
 
                  const page = currentPage[accountName] || 1;
                  const paginatedFlowers = paginate(flowers, page);
 
                  return (
                    <div key={shopIndex} className="client-florist-shop">
                      <button
                        onClick={() => handleStoreClick(accountName, floristProfile, store)}
                        className="client-store-button"
                      >
                        <img
                          src={floristProfile.profilePicture ? floristProfile.profilePicture : EmptyProfilePic}
                          alt={`${floristProfile.firstName || 'Florist'} ${floristProfile.lastName || ''}`}
                          className="client-profile-picture-thumbnail"
                        />
                        {`${floristProfile.firstName}'s Mini Shop`}
                      </button>
                      <div className="client-flower-list">
                        {paginatedFlowers.map((flower, index) => (
                          <div
                            key={index}
                            className="client-flower-item"
                            onClick={() => handleOpenModal(flower, floristProfile, store)}
                          >
                            <div className="client-flower-image-container">
                              <img src={flower.image} alt="Flower" className="client-flower-image" />
                            </div>
                            <p className="client-flower-title">{flower.title}</p>
                            <p className="client-flower-price">${flower.price}</p>
                          </div>
                        ))}
                      </div>
                      <div className="client-pagination-buttons">
                        <button 
                          onClick={() => handlePreviousPage(accountName)} 
                          disabled={page === 1}
                        >
                          Previous
                        </button>
                        <button 
                          onClick={() => handleNextPage(accountName)} 
                          disabled={page * itemsPerPage >= flowers.length}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
 
      {selectedFlower && (
        <FlowerModal
          flower={selectedFlower}
          accessories={accessories}
          onClose={handleCloseModal}
          onAddToCart={(flower, selectedAccessories) => {
            handleAddToCart(flower, selectedAccessories);
            handleCloseModal();
          }}
        />
      )}
 
      <ChatWidget userType="Client" userName="Guest" chatType="client-admin" recipient="Admin" />
      <footer className="client-page-footer">
        <p>© 2024 MiniFlora - All rights reserved.</p>
      </footer>
    </div>
  );
 };
 
 export default ClientPageApp;
 
