import React, { useState } from 'react';
import './FlowerModal.css'; // Import the CSS for styling

const FlowerModal = ({ flower, accessories = [], onClose, onAddToCart }) => {
  const [selectedAccessories, setSelectedAccessories] = useState({});

  if (!flower) return null; // Don't render anything if no flower is selected

  // Function to handle accessory quantity change
  const handleAccessoryChange = (accessory, quantity) => {
    setSelectedAccessories((prevSelected) => {
      if (quantity > 0) {
        return { ...prevSelected, [accessory.title]: { ...accessory, quantity } };
      } else {
        const updatedAccessories = { ...prevSelected };
        delete updatedAccessories[accessory.title];
        return updatedAccessories;
      }
    });
  };
  
  // Function to handle adding to cart
  const handleAddToCart = () => {
    const totalPrice = Object.values(selectedAccessories).reduce(
      (sum, accessory) => sum + parseFloat(accessory.price) * accessory.quantity,
      parseFloat(flower.price)
    );
  
    const flowerWithAccessories = {
      ...flower,
      accessories: Object.values(selectedAccessories), // Pass the accessory quantities
      totalPrice: totalPrice.toFixed(2), // Format price to 2 decimal places
    };
  
    onAddToCart(flowerWithAccessories, Object.values(selectedAccessories)); // Add to cart with quantities
    onClose(); // Close modal after adding to cart
  };

  return (
    <div className="flower-modal-unique-overlay" onClick={onClose}>
      <div className="flower-modal-unique-content" onClick={(e) => e.stopPropagation()}>
        <button className="flower-modal-unique-close-btn" onClick={onClose}>X</button>

        <div className="flower-modal-unique-left">
          <img src={flower.image} alt="Flower" className="flower-modal-unique-image" />
        </div>

        <div className="flower-modal-unique-right">
          <h3 className="flower-modal-unique-title">{flower.title}</h3>
          <p className="flower-modal-unique-description"><strong>Description:</strong> {flower.description}</p>
          <p className="flower-modal-unique-price"><strong>Price:</strong> ${flower.price}</p>

          {/* Show accessories if available */}
          {accessories.length > 0 ? (
            <>
              <h4>Select Accessories</h4>
              <ul className="flower-modal-unique-accessories-list">
                {accessories.map((accessory, index) => (
                  <li key={index} className="flower-modal-unique-accessory-item">
                    <label>
                      <img
                        src={accessory.image}
                        alt={accessory.title}
                        className="flower-modal-unique-accessory-thumbnail"
                      />
                      <span className="flower-modal-unique-accessory-title">{accessory.title}</span> - ${accessory.price}
                      <input
                        type="number"
                        min="0"
                        max="10" // Optionally set a maximum
                        value={selectedAccessories[accessory.title]?.quantity || 0}
                        onChange={(e) => handleAccessoryChange(accessory, parseInt(e.target.value) || 0)}
                        className="flower-modal-unique-accessory-quantity"
                      />
                    </label>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p>No accessories available for this flower.</p>
          )}

          <button
            className="flower-modal-unique-add-to-cart-btn"
            onClick={handleAddToCart}
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default FlowerModal;
