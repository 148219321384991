import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { db } from './Payment/firebase';
import './SalesAnalysis.css';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const PLATFORM_FEE_RATE = 0.1; // 10% platform fee

const SalesAnalysis = ({ floristProfile }) => {
  const [salesData, setSalesData] = useState([]);
  const [timeframe, setTimeframe] = useState('day'); // Default to day
  const [itemType, setItemType] = useState('flower'); // Default to flower
  const [chartData, setChartData] = useState(null); // State to store chart data
  const [visitorCount, setVisitorCount] = useState(0); // State for visitor count
  const [totalRevenue, setTotalRevenue] = useState(0); // Total revenue state
  const [accountReceivable, setAccountReceivable] = useState(0); // Account receivable state
  const [totalTips, setTotalTips] = useState(0); // Store total tips
  const [totalTax, setTotalTax] = useState(0); // Store total tax
  const [totalDeliveryFee, setTotalDeliveryFee] = useState(0); // Store total delivery fee
  const [topFlowers, setTopFlowers] = useState([]); // Store top 5 flowers
  const [topAccessories, setTopAccessories] = useState([]); // Store top 5 accessories

  // Fetch sales data from Firestore
  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const salesCollection = collection(db, 'sales');
        const salesQuery = query(salesCollection, where('florist.uid', '==', floristProfile?.uid || ''));
        const salesSnapshot = await getDocs(salesQuery);
        const sales = salesSnapshot.docs.map((doc) => doc.data());

        setSalesData(sales);
        calculateRevenue(sales); // Calculate revenue based on the sales
        calculateTopSellingItems(sales); // Calculate top-selling items
      } catch (error) {
        console.error('Error fetching sales data:', error);
      }
    };

    if (floristProfile?.uid) {
      fetchSalesData();
    }
  }, [floristProfile]);

  // Generate chart data based on the sales
  useEffect(() => {
    if (salesData.length > 0) {
      generateChartData();
    }
  }, [timeframe, itemType, salesData]);

  // Fetch visitor count from Firestore
  useEffect(() => {
    const fetchVisitorCount = async () => {
      try {
        const storeRef = doc(db, 'stores', floristProfile?.accountName); // Reference to the florist's store
        const storeSnapshot = await getDoc(storeRef);
        if (storeSnapshot.exists()) {
          const storeData = storeSnapshot.data();
          setVisitorCount(storeData.visitorCount || 0); // Set visitor count from Firestore
        }
      } catch (error) {
        console.error('Error fetching visitor count:', error);
      }
    };

    if (floristProfile?.accountName) {
      fetchVisitorCount();
    }
  }, [floristProfile]);

  const generateChartData = () => {
    let labels = [];
    let data = [];

    if (timeframe === 'day') {
      labels = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      data = Array(7).fill(0);
    } else if (timeframe === 'month') {
      labels = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
      data = Array(4).fill(0);
    } else if (timeframe === 'year') {
      labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      data = Array(12).fill(0);
    }

    salesData.forEach((sale) => {
      const saleDate = sale.dateDelivered.toDate();

      if (timeframe === 'day') {
        const dayIndex = saleDate.getDay();
        data[dayIndex]++;
      } else if (timeframe === 'month') {
        const weekIndex = Math.floor(saleDate.getDate() / 7);
        if (weekIndex < 4) data[weekIndex]++;
      } else if (timeframe === 'year') {
        const monthIndex = saleDate.getMonth();
        data[monthIndex]++;
      }
    });

    setChartData({
      labels,
      datasets: [
        {
          label: `Sales (${itemType})`,
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 1,
          data: data || [],
        },
      ],
    });
  };

  const calculateRevenue = (sales) => {
    let dailyRevenue = 0;
    let monthlyRevenue = 0;
    let yearlyRevenue = 0;
    let tips = 0;
    let tax = 0;
    let deliveryFee = 0;
  
    const now = new Date();
  
    sales.forEach((sale) => {
      const saleDate = sale.dateDelivered.toDate();
  
      // Exclude the tip and delivery fee from the total amount calculation
      const totalAmount = parseFloat(sale.totalAmount) - parseFloat(sale.tipAmount || 0) - parseFloat(sale.deliveryFee || 0) || 0;
  
      const isToday = saleDate.toDateString() === now.toDateString();
      const isThisMonth = saleDate.getMonth() === now.getMonth() && saleDate.getFullYear() === now.getFullYear() && !isToday;
      const isThisYear = saleDate.getFullYear() === now.getFullYear();
  
      tips += parseFloat(sale.tipAmount || 0);
      deliveryFee += parseFloat(sale.deliveryFee || 0);
      const saleTax = sale.tax !== undefined && sale.tax !== null ? parseFloat(sale.tax) : (totalAmount * 0.12);
      tax += saleTax;
  
      if (isToday) {
        dailyRevenue += totalAmount;
      } else if (isThisMonth) {
        monthlyRevenue += totalAmount;
      }
      if (isThisYear) {
        yearlyRevenue += totalAmount;
      }
    });
  
    const platformFees = {
      daily: dailyRevenue * PLATFORM_FEE_RATE,
      monthly: monthlyRevenue * PLATFORM_FEE_RATE,
      yearly: yearlyRevenue * PLATFORM_FEE_RATE,
    };
  
    const accountReceivable = {
      daily: dailyRevenue - platformFees.daily,
      monthly: monthlyRevenue - platformFees.monthly,
      yearly: yearlyRevenue - platformFees.yearly,
    };
  
    setTotalRevenue({
      daily: dailyRevenue.toFixed(2),
      monthly: monthlyRevenue.toFixed(2),
      yearly: yearlyRevenue.toFixed(2),
    });
  
    setTotalTips(tips.toFixed(2));
    setTotalTax(tax.toFixed(2));
    setTotalDeliveryFee(deliveryFee.toFixed(2));
  
    setAccountReceivable({
      daily: accountReceivable.daily.toFixed(2),
      monthly: accountReceivable.monthly.toFixed(2),
      yearly: accountReceivable.yearly.toFixed(2),
    });
  };
  

  const calculateTopSellingItems = (sales) => {
    const flowerCounts = {};
    const accessoryCounts = {};
  
    sales.forEach((sale) => {
      sale.cartItems.forEach((item) => {
        // Handle flowers as before
        if (item.type === 'flower') {
          if (flowerCounts[item.title]) {
            flowerCounts[item.title].count += 1;
          } else {
            flowerCounts[item.title] = {
              title: item.title,
              count: 1,
              image: item.image || '',
            };
          }
        }
  
        // Handle nested accessories array
        if (Array.isArray(item.accessories)) {
          item.accessories.forEach((accessory) => {
            if (accessory.type === 'accessory') {
              if (accessoryCounts[accessory.title]) {
                accessoryCounts[accessory.title].count += 1;
              } else {
                accessoryCounts[accessory.title] = {
                  title: accessory.title,
                  count: 1,
                  image: accessory.image || '',
                };
              }
            }
          });
        }
      });
    });
  
    // Get top 5 flowers and accessories based on the count
    const topFlowersList = Object.values(flowerCounts)
      .sort((a, b) => b.count - a.count)
      .slice(0, 5);
  
    const topAccessoriesList = Object.values(accessoryCounts)
      .sort((a, b) => b.count - a.count)
      .slice(0, 5);
  
    setTopFlowers(topFlowersList);
    setTopAccessories(topAccessoriesList);
  };
  
  

  const getDynamicYMaxValue = () => {
    const maxSales = Math.max(...(chartData?.datasets?.[0]?.data || [0]));
    const nextMultipleOf100 = Math.ceil(maxSales / 100) * 100;
    return nextMultipleOf100 < 100 ? 100 : nextMultipleOf100;
    
  };

  return (
    <div className="sales-analysis">
      <h2>Sales Analysis</h2>

      {/* Visitor Counter Display */}
      <div className="visitor-counter">
        <p>Visitor Count: {visitorCount}</p>
      </div>

      {/* Timeframe Toggle */}
      <div className="timeframe-toggle">
        <button onClick={() => setTimeframe('day')} className={timeframe === 'day' ? 'active' : ''}>
          Day
        </button>
        <button onClick={() => setTimeframe('month')} className={timeframe === 'month' ? 'active' : ''}>
          Month
        </button>
        <button onClick={() => setTimeframe('year')} className={timeframe === 'year' ? 'active' : ''}>
          Year
        </button>
      </div>

      {/* Item Type Toggle */}
      <div className="item-type-toggle">
        <button onClick={() => setItemType('flower')} className={itemType === 'flower' ? 'active' : ''}>
          Flower
        </button>
        <button onClick={() => setItemType('accessory')} className={itemType === 'accessory' ? 'active' : ''}>
          Accessory
        </button>
      </div>

      {/* Chart */}
      <div className="chart-container">
        {chartData?.datasets?.length > 0 ? (
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  beginAtZero: true,
                  max: getDynamicYMaxValue(),
                  ticks: {
                    stepSize: 1,
                    color: '#333', // Y-axis tick color
                  },
                },
                x: {
                  ticks: {
                    color: '#333', // X-axis tick color
                  },
                },
              },
              plugins: {
                legend: {
                  labels: {
                    color: '#black', // Legend text color
                  },
                },
                title: {
                  display: true,
                  text: `Sales Analysis (${timeframe})`,
                  color: '#black', // Title color
                },
              },
            }}
          />
        ) : (
          <p>No sales data available.</p>
        )}
      </div>

      {/* Performance Metrics */}
      <div className="performance-metrics">
        <h3>Performance Overview</h3>
        <p><strong>Total Daily Revenue:</strong> ${totalRevenue.daily}</p>
        <p><strong>Account Receivable (less Platform Fees, Daily):</strong> ${accountReceivable.daily}</p>

        <p><strong>Total Monthly Revenue:</strong> ${totalRevenue.monthly}</p>
        <p><strong>Account Receivable (less Platform Fees, Monthly):</strong> ${accountReceivable.monthly}</p>

        <p><strong>Total Yearly Revenue:</strong> ${totalRevenue.yearly}</p>
        <p><strong>Account Receivable (less Platform Fees, Yearly):</strong> ${accountReceivable.yearly}</p>

        <p><strong>Total (GST & PST):</strong> ${totalTax}</p>
      </div>

      {/* Top Selling Items Section */}
      <div className="top-selling-items">
  <h3>Top 5 Hot Selling Items</h3>

  <div className="top-selling-container"> {/* New container div for side-by-side layout */}
    <div className="top-selling-flowers">
      <h4>Top 5 Flowers</h4>
      {topFlowers.length > 0 ? (
        topFlowers.map((flower, index) => (
          <div key={index} className="top-item">
            {flower.image && <img src={flower.image} alt={flower.title} className="top-item-thumbnail" />}
            <p>{flower.title} - Sold: {flower.count}</p>
          </div>
        ))
      ) : (
        <p>No top flowers available.</p>
      )}
    </div>

    <div className="top-selling-accessories">
      <h4>Top 5 Accessories</h4>
      {topAccessories.length > 0 ? (
        topAccessories.map((accessory, index) => (
          <div key={index} className="top-item">
            {accessory.image && <img src={accessory.image} alt={accessory.title} className="top-item-thumbnail" />}
            <p>{accessory.title} - Sold: {accessory.count}</p>
          </div>
        ))
      ) : (
        <p>No top accessories available.</p>
      )}
    </div>
  </div>
</div>
    </div>
  );
};


export default SalesAnalysis;
