import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from './logo.png';
import './Navbar.css';
import EmptyProfile from './EmptyProfile.jpg';
import ShoppingCartIcon from './ShoppingCart.png'; // Import the cart icon

const NavbarUnique = ({
  floristProfile,
  customerProfile,
  supplierProfile, // Added supplierProfile
  setFloristProfile,
  setCustomerProfile,
  setSupplierProfile, // Added setSupplierProfile
  handleLogout,
  isAdminLoggedIn,
  cart = [], // Ensure cart is always initialized as an empty array by default
  totalAmount = 0, // Ensure totalAmount is always initialized as 0 by default
  setCart, // Include the setCart function to update cart items
}) => {
  const [profile, setProfile] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false); // New state for cart dropdown
  const [calculatedTotal, setCalculatedTotal] = useState(0); // State for recalculating total
  const navigate = useNavigate();

  // Set the profile information (either florist, customer, or supplier)
  useEffect(() => {
    if (floristProfile) {
      setProfile(floristProfile);
    } else if (customerProfile) {
      setProfile(customerProfile);
    } else if (supplierProfile) {
      setProfile(supplierProfile); // Set profile if supplier is logged in
    }
  }, [floristProfile, customerProfile, supplierProfile]);

  // Recalculate the total amount whenever the cart changes
  useEffect(() => {
    const calculateTotal = () => {
      let total = 0;
      cart.forEach((item) => {
        total += parseFloat(item.price);

        if (item.accessories && item.accessories.length > 0) {
          item.accessories.forEach((accessory) => {
            total += parseFloat(accessory.price) * accessory.quantity;
          });
        }
      });
      setCalculatedTotal(total); // Set the total amount
    };

    calculateTotal();
  }, [cart]);

  const handleLogoutClick = async () => {
    localStorage.clear();
    if (setFloristProfile) {
      await new Promise((resolve) => {
        setFloristProfile(null);
        resolve();
      });
    }
    if (setCustomerProfile) {
      await new Promise((resolve) => {
        setCustomerProfile(null);
        resolve();
      });
    }
    if (setSupplierProfile) {
      await new Promise((resolve) => {
        setSupplierProfile(null);
        resolve();
      });
    }
    if (handleLogout) {
      handleLogout();
    }
    navigate('/');
  };

  const handleCartClick = () => {
    setIsCartOpen((prevState) => !prevState); // Toggle cart dropdown
  };

  const handleCheckout = () => {
    if (cart.length > 0) {
      // Verify if floristProfile is present in the first cart item
      const floristProfile = cart[0]?.floristProfile;
      const floristUid = floristProfile?.uid || null; // Access floristUid safely
      if (!floristUid) {
        alert('Florist information is missing.');
        return;
      }
  
      navigate('/payment', {
        state: {
          cartItems: cart,
          totalAmount: calculatedTotal,
          floristUid: floristUid,
          floristAddress: floristProfile.address,
        },
      });
    } else {
      alert('Your cart is empty.');
    }
  };
  
  const handleDeleteFromCart = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart); // Update the cart after removing the item
  };

  let profilePicture = EmptyProfile;
  let displayName = 'Guest';

  if (supplierProfile) {
    // If supplierProfile exists, display company name
    profilePicture = supplierProfile.profilePicture || EmptyProfile;
    displayName = supplierProfile.companyName || 'Supplier';
  } else if (floristProfile) {
    profilePicture = floristProfile.profilePicture || EmptyProfile;
    displayName = `${floristProfile.firstName} ${floristProfile.lastName}`;
  } else if (customerProfile) {
    profilePicture = customerProfile.profilePicture || EmptyProfile;
    displayName = `${customerProfile.firstName} ${customerProfile.lastName}`;
  } else if (isAdminLoggedIn) {
    displayName = 'Admin';
  }

  return (
    <div className="navbar-unique">
      <div className="navbar-content-unique">
        {/* Logo Section on the Left */}
        <Link to="/" className="logo-container-unique">
          <img src={logo} alt="MiniFlora Logo" className="app-logo-unique" />
        </Link>
        <h3>MINIFLORA</h3>

        {/* Profile Section on the Right */}
        <div className="navbar-right-section">
          {/* Shopping Cart Section */}
          <div
            className="shopping-cart-section"
            onMouseEnter={() => setIsCartOpen(true)}
            onMouseLeave={() => setIsCartOpen(false)}
          >
            <button className="shopping-cart-button" onClick={handleCartClick}>
              <img src={ShoppingCartIcon} alt="Cart" className="shopping-cart-icon" />
            </button>
            {isCartOpen && (
              <div className="dropdown-cart-unique">
                <h3>Your Cart</h3>
                {cart.length > 0 ? (
                  <>
                    {cart.map((item, index) => (
                      <div key={index} className="cart-item">
                        <img src={item.image} alt="Flower" className="cart-item-image" />
                        <p>{item.title} - ${item.price}</p>

                        {item.accessories && item.accessories.length > 0 && (
                          <div className="accessory-list">
                            {item.accessories.map((accessory, i) => (
                              <div key={i} className="cart-accessory">
                                <img src={accessory.image} alt="Accessory" className="accessory-thumbnail" />
                                <span>{accessory.title} (x{accessory.quantity}) - ${accessory.price} each</span>
                              </div>
                            ))}
                          </div>
                        )}
                        <button
                          className="delete-cart-item-button"
                          onClick={() => handleDeleteFromCart(index)}
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                    <p><strong>Total: </strong>${calculatedTotal.toFixed(2)}</p>
                    <button className="checkout-button" onClick={handleCheckout}>Check Out</button>
                  </>
                ) : (
                  <p>Your cart is empty.</p>
                )}
              </div>
            )}
          </div>

          {/* Profile Section */}
          <div
            className="profile-section-unique"
            onMouseEnter={() => setIsDropdownOpen(true)}
            onMouseLeave={() => setIsDropdownOpen(false)}
          >
            <button className="profile-button-unique">
              <img src={profilePicture} alt="Profile" className="profile-picture-unique" />
              {displayName} ▼
            </button>
            {isDropdownOpen && (
              <div className="dropdown-menu-unique">
                {isAdminLoggedIn ? (
                  <>
                    <Link to="/delivery" className="dropdown-item-unique">Delivery</Link>
                    <Link to="/florist-management" className="dropdown-item-unique">Florist Management</Link>
                    <Link to="/customer-management" className="dropdown-item-unique">Customer Management</Link>
                    <Link to="/chat-history" className="dropdown-item-unique">Chat History</Link>
                    <Link to="/accounting" className="dropdown-item-unique">Accounting</Link>
                    <button className="dropdown-item-unique" onClick={handleLogoutClick}>Log Out</button>
                  </>
                ) : floristProfile ? (
                  <>
                    <Link to="/account" className="dropdown-item-unique">Account Information</Link>
                    <Link to="/store" className="dropdown-item-unique">Store Information</Link>
                    <Link to="/crm" className="dropdown-item-unique">CRM</Link>
                    <Link to="/sales-analysis" className="dropdown-item-unique">Sales Analysis</Link>
                    <button className="dropdown-item-unique" onClick={handleLogoutClick}>Log Out</button>
                  </>
                ) : customerProfile ? (
                  <>
                    <button className="dropdown-item-unique" onClick={handleLogoutClick}>Log Out</button>
                  </>
                ) : supplierProfile ? (
                  <>
                    <Link to="/supplier/store" className="dropdown-item-unique">Supplier Store</Link>
                    <button className="dropdown-item-unique" onClick={handleLogoutClick}>Log Out</button>
                  </>
                ) : (
                  <>
                    <Link to="/login" className="dropdown-item-unique">Florist Log in</Link>
                    <Link to="/customer-login" className="dropdown-item-unique">Customer Log in</Link>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarUnique;

//<Link to="/supplier-profiles" className="dropdown-item-unique">Supplier Store</Link> {/* Link to SupplierProfiles.js */}
//<Link to="/supplier/login" className="dropdown-item-unique">Supplier Log in</Link>