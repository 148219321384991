import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, orderBy, addDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from './Payment/firebase'; // Adjust this import based on the actual firebase location
import io from 'socket.io-client';
import './ChatHistoryPage.css';

const socket = io('https://miniflora-socket-server-495106690462.us-central1.run.app', {
  transports: ['websocket', 'polling'], // Allow WebSocket with fallback to polling
  secure: true,
});


const ChatHistoryPage = ({ isAdminLoggedIn }) => {
  const [chatHistory, setChatHistory] = useState([]);
  const [activeTab, setActiveTab] = useState(''); // Store the active tab (florist or guest)
  const [newMessage, setNewMessage] = useState(''); // New message input
  const [currentMessages, setCurrentMessages] = useState([]); // Current conversation messages
  const navigate = useNavigate();

  // Fetch chat history from Firestore
  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const chatCollectionRef = collection(db, 'chats'); // Reference to the chats collection
        const chatQuery = query(chatCollectionRef, orderBy('timestamp', 'asc')); // Order chats by timestamp
        const chatSnapshot = await getDocs(chatQuery);

        // Ensure that we are capturing both Firestore document ID and the custom ID in the content
        const chatData = chatSnapshot.docs.map((doc) => ({
          firestoreDocId: doc.id,  // This is the Firestore-generated document ID we will use for updates
          ...doc.data(),           // Spread the rest of the chat data (including the custom `id` field)
        }));

        console.log("Fetched chat data with Firestore document IDs:", chatData);

        setChatHistory(chatData);

        // Automatically set the first user or florist as the active tab if data exists
        if (chatData.length > 0) {
          setActiveTab(chatData[0].sender);
          setCurrentMessages(chatData.filter(chat => chat.sender === chatData[0].sender || chat.recipient === chatData[0].sender));
        }
      } catch (error) {
        console.error('Error fetching chat history:', error);
      }
    };

    if (isAdminLoggedIn) {
      fetchChatHistory();
    } else {
      navigate('/'); // Redirect to home if not logged in as admin
    }
  }, [isAdminLoggedIn, navigate]);

  const handleReturnHome = () => {
    navigate('/'); // Navigate to home page
  };

  // Send a new message and update the current chat
  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      const messageData = {
        sender: 'Admin',
        recipient: activeTab,
        content: `${newMessage}`, // Only the new message content
        timestamp: new Date(),
        chatType: 'florist-admin',
        read: false, // Default to unread
      };
  
      // Find the current chat document using Firestore document ID
      const currentChat = chatHistory.find(
        (chat) => (chat.sender === activeTab && chat.recipient === 'Admin') || 
                  (chat.sender === 'Admin' && chat.recipient === activeTab)
      );
  
      if (currentChat && currentChat.firestoreDocId && typeof currentChat.firestoreDocId === 'string') {
        console.log(`Updating existing chat with Firestore document ID: ${currentChat.firestoreDocId}`);
        const currentChatRef = doc(db, 'chats', currentChat.firestoreDocId); // Use Firestore document ID
  
        try {
          // Append the new message to the existing content with a newline character
          await updateDoc(currentChatRef, {
            content: `${currentChat.content}\n${messageData.sender}: ${messageData.content}`,  // Newline before appending
            timestamp: messageData.timestamp,  // Update timestamp
            read: messageData.read
          });
          console.log('Message appended successfully in Firestore.');
  
          // Update the local chat history
          setCurrentMessages((prevMessages) => [...prevMessages, messageData]);
          setNewMessage(''); // Clear the input field
        } catch (error) {
          console.error('Error updating Firestore:', error);
        }
      } else {
        console.error(`Invalid or missing Firestore document ID.`);
      }
    }
  };
  

  // Handle new message input change
  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
    socket.emit('typing', { userType: 'Admin', chatType: 'florist-admin', isTyping: true });
  };

  // Close the typing indicator
  const handleBlur = () => {
    socket.emit('typing', { userType: 'Admin', chatType: 'florist-admin', isTyping: false });
  };

  // Handle switching tabs
  const handleTabChange = (sender) => {
    setActiveTab(sender);
    setCurrentMessages(chatHistory.filter(chat => chat.sender === sender || chat.recipient === sender));
  };

  return (
    <div className="chat-history-page-container">
      <h2 className="chat-history-title">Chat History</h2>

      {/* Tab Navigation */}
      <div className="chat-tabs">
        {chatHistory.map((chat, index) => (
          <button
            key={index}
            className={`chat-tab-button ${activeTab === chat.sender ? 'active' : ''}`}
            onClick={() => handleTabChange(chat.sender)}
          >
            {chat.sender} {/* This will show florist name or guest IP */}
          </button>
        ))}
      </div>

      {/* Display chat history for the selected tab */}
      {currentMessages.length > 0 ? (
        <div className="chat-history-list">
          <div className="chat-history-item-messages">
            {currentMessages.map((message, index) => (
              <p key={index}><strong>{message.sender}:</strong> {message.content}</p>
            ))}
          </div>
        </div>
      ) : (
        <p>No chat messages available for {activeTab}</p>
      )}

      {/* Message input for Admin to send new messages */}
      <div className="chat-history-input-box">
        <input
          type="text"
          value={newMessage}
          onChange={handleInputChange}
          placeholder="Type your message..."
          onBlur={handleBlur}
        />
        <button onClick={handleSendMessage} disabled={!newMessage.trim()}>Send</button>
      </div>

      {/* Return Home Button */}
      <button onClick={handleReturnHome} className="return-home-button">
        Return Home
      </button>
    </div>
  );
};

export default ChatHistoryPage;
