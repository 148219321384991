import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore'; // Firestore imports
import { db } from '../Payment/firebase'; // Firestore instance (adjust path if necessary)
import './CustomerLogIn.css'; // Import customer-specific styles

const CustomerLogIn = ({ setCustomerProfile }) => {
  const [accountName, setAccountName] = useState(''); // Use accountName instead of email
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Query Firestore to find the customer profile by accountName
      const customerCollection = collection(db, 'customerProfiles');
      const q = query(customerCollection, where('accountName', '==', accountName));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Get the first matching document (assuming unique accountName)
        const customerDoc = querySnapshot.docs[0].data();

        // Check if the password matches
        if (customerDoc.password === password) {
          // Login successful
          setCustomerProfile(customerDoc);
          alert('Login successful!');
          navigate('/customer-profile'); // Redirect to customer profile page
        } else {
          setError('Invalid account name or password.');
        }
      } else {
        setError('No account found.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError('An error occurred. Please try again.');
    }
  };

  const handleSignUp = () => {
    navigate('/customer-signup'); // Redirect to the customer sign-up page
  };

  const handleReturnHome = () => {
    navigate('/'); // Redirect to the home page
  };

  return (
    <div className="customer-login-page">
      <h2>Customer Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Account Name"
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {error && <p className="error">{error}</p>}
        <button type="submit" className="btn">Log In</button>
      </form>

      <button onClick={handleSignUp} className="btn signup-btn">Create an Account</button>
      <button onClick={handleReturnHome} className="btn home-btn">Return Home</button>
    </div>
  );
};

export default CustomerLogIn;
