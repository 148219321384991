import React, { useState, useEffect } from 'react';
import './Delivery.css'; // Updated to distinctive stylesheet
import { useNavigate } from 'react-router-dom';
import { db, storage } from '../Payment/firebase'; 
import { collection, getDocs, query, doc, updateDoc, deleteDoc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import UploadIcon from '../upload.png'; // Importing the upload image
import { PLATFORM_FEE_RATE } from '../Accounting/Accounting';


const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Delivery = ({ isAdminLoggedIn, feeRate }) => {
  const [orders, setOrders] = useState([]);
  const [florists, setFlorists] = useState([]);
  const [mapUrl, setMapUrl] = useState('');
  const [currentRoute, setCurrentRoute] = useState([]);
  const [uploading, setUploading] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [sortedFlorists, setSortedFlorists] = useState([]);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [visitorCount, setVisitorCount] = useState(0); // Visitor count state
  const [routeDetails, setRouteDetails] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrdersAndFlorists = async () => {
      try {
        // Fetch Orders
        const ordersCollectionRef = collection(db, 'orders');
        const ordersSnapshot = await getDocs(ordersCollectionRef);
        const ordersData = ordersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setOrders(ordersData);
  
        // Fetch Florists
        const floristsCollectionRef = collection(db, 'floristProfiles'); // Corrected collection name to 'floristProfiles'
        const floristsSnapshot = await getDocs(floristsCollectionRef);
        const floristsData = floristsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setFlorists(floristsData);
        
        
      } catch (error) {
        console.error('Error fetching orders or florists:', error);
      }
    };
  
    fetchOrdersAndFlorists();
  
    setMapUrl(
      `https://www.google.com/maps/embed/v1/view?key=${apiKey}&center=49.2827,-123.1207&zoom=10`
    );
  
    incrementVisitorCount(); // Increment the visitor count when this page is loaded
  }, []); // Ensure this runs once after the component is mounted
  

  const incrementVisitorCount = async () => {
    try {
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      const { ip } = await ipResponse.json();

      const visitorDocRef = doc(db, 'visitorCount', 'uniqueVisitors');
      const visitorSnapshot = await getDoc(visitorDocRef);

      if (visitorSnapshot.exists()) {
        const visitorData = visitorSnapshot.data();
        if (!visitorData.visitors || !visitorData.visitors.includes(ip)) {
          await updateDoc(visitorDocRef, {
            count: (visitorData.count || 0) + 1,
            visitors: [...(visitorData.visitors || []), ip],
          });
          setVisitorCount((visitorData.count || 0) + 1);
        } else {
          setVisitorCount(visitorData.count);
        }
      } else {
        await setDoc(visitorDocRef, {
          count: 1,
          visitors: [ip],
        });
        setVisitorCount(1);
      }
    } catch (error) {
      console.error('Error incrementing visitor count:', error);
    }
  };

  const handleDeleteOrder = async (orderId) => {
    const confirmDelete = window.confirm('Are you sure you want to cancel this delivery order?');
    if (confirmDelete) {
      try {
        // Find the order details to include in the notification
        const order = orders.find((o) => o.id === orderId);
        const { recipientInfo, florist } = order;
  
        // Prepare email addresses
        const adminEmail = process.env.REACT_APP_ADMIN_EMAIL; // Ensure this is set in your environment
        const customerEmail = recipientInfo?.email;
        const floristEmail = florist?.email;
  
        // Prepare order details
        const orderDetails = {
          items: order.cartItems || [],
          sender: order.sender,
          recipient: `${recipientInfo.firstName} ${recipientInfo.lastName}`,
          deliveryAddress: recipientInfo.address,
          totalAmount: order.totalAmount,
        };
  
        // Send email notifications by calling the cancellation endpoint
        console.log("Starting to send cancellation notification...");
        await fetch('https://us-central1-miniflora-26d96.cloudfunctions.net/api/notify-order-canceled', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            orderId,
            customerEmail,
            floristEmail,
            adminEmail,
            orderDetails
          }),
        });
  
        // Delete the order from Firestore
        const orderDocRef = doc(db, 'orders', orderId);
        await deleteDoc(orderDocRef);
  
        // Update the local orders state
        setOrders((prevOrders) => prevOrders.filter((o) => o.id !== orderId));
  
        alert('Order successfully canceled and removed!');
      } catch (error) {
        console.error('Error deleting the order:', error);
        alert('Failed to cancel the order. Please try again.');
      }
    }
  };

  const handleReassignFlorist = (order) => {
    setSelectedOrder(order);
    sortFloristsByDistance(order.recipientInfo.address);
    setShowReassignModal(true);
  };

  const calculateDistance = (origin, destination) => {
    return Math.random() * 100;
  };

  const sortFloristsByDistance = (deliveryAddress) => {
    const sorted = florists
      .map((florist) => ({
        ...florist,
        distance: calculateDistance(florist.address, deliveryAddress),
      }))
      .sort((a, b) => a.distance - b.distance);

    setSortedFlorists(sorted);
  };

  const handleAssignNewFlorist = async (florist) => {
    if (!selectedOrder) return;

    try {
        // Update the Firestore document with the new florist details
        const orderDocRef = doc(db, 'orders', selectedOrder.id);
        await updateDoc(orderDocRef, {
            florist: {
                id: florist.id,
                name: `${florist.firstName} ${florist.lastName}`,
                address: florist.address,
                email: florist.email,
            },
        });

        // Update the local orders state with the new florist information
        setOrders((prevOrders) =>
            prevOrders.map((order) =>
                order.id === selectedOrder.id
                    ? {
                        ...order,
                        florist: {
                            id: florist.id,
                            name: `${florist.firstName} ${florist.lastName}`,
                            address: florist.address,
                            email: florist.email,
                        },
                    }
                    : order
            )
        );

        // Alert with the florist's name to confirm reassignment
        alert(`Order reassigned to ${florist.firstName} ${florist.lastName} successfully!`);
        
        // Send reassign notification to the new florist
        await fetch('/api/sendReassignNotification', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                floristEmail: florist.email,
                floristName: `${florist.firstName} ${florist.lastName}`,
                orderId: selectedOrder.id,
                customerName: `${selectedOrder.recipientInfo.firstName} ${selectedOrder.recipientInfo.lastName}`,
                deliveryAddress: selectedOrder.recipientInfo.address,
            }),
        });

        setSelectedOrder(null);
        setShowReassignModal(false);
    } catch (error) {
        console.error('Error reassigning florist:', error);
        alert('Failed to reassign florist. Please try again.');
    }
};

  const handleMarkAsDelivered = async (order) => {
    if (!isAdminLoggedIn) {
      alert('Only admins can mark orders as delivered.');
      return;
    }

    try {
      const orderDocRef = doc(db, 'orders', order.id);
      await updateDoc(orderDocRef, { status: 'Delivered' });
      setOrders(orders.map((o) => (o.id === order.id ? { ...o, status: 'Delivered' } : o)));
      alert(`Order ${order.id} marked as delivered successfully!`);
    } catch (error) {
      console.error('Error marking order as delivered:', error);
    }
  };

  const handleCompleteOrder = async (order) => {
  const confirmComplete = window.confirm('Are you sure you want to complete this order?');
  if (!confirmComplete) return;

  try {
    const { id, ...orderData } = order;

    // Add order to "sales" collection, including platform fee rate
    const salesDocRef = doc(db, 'sales', id);
    await setDoc(salesDocRef, {
      ...orderData,
      dateDelivered: new Date(), // Record the delivery date
      status: 'Completed',
      platformFeeRate: feeRate, // Include current platform fee rate here
    });

    // Remove the order from "orders" collection
    await deleteDoc(doc(db, 'orders', id));

    // Update the state to remove the order from the list
    setOrders((prevOrders) => prevOrders.filter((o) => o.id !== id));

    alert('Order completed and archived successfully.');
  } catch (error) {
    console.error('Error completing order:', error);
    alert('Failed to complete the order. Please try again.');
  }
};

  const handleImageUpload = async (orderId, file) => {
    if (!file) return;

    const storageRef = ref(storage, `delivery_images/${orderId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setUploading(orderId);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        console.log(`Upload progress: ${(snapshot.bytesTransferred / snapshot.totalBytes) * 100}%`);
      },
      (error) => {
        console.error('Upload failed:', error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          await updateDoc(doc(db, 'orders', orderId), { deliveryImage: downloadURL });

          setOrders((prevOrders) =>
            prevOrders.map((order) =>
              order.id === orderId ? { ...order, deliveryImage: downloadURL } : order
            )
          );

          setUploading(null);
          alert('Image uploaded successfully!');
        } catch (error) {
          console.error('Error updating order with image URL:', error);
        }
      }
    );
  };

  const handleSetRoute = (floristAddress, deliveryAddress, order) => {
    const newRoute = currentRoute.length === 0 
        ? [floristAddress, deliveryAddress] 
        : [...currentRoute, deliveryAddress];

    setCurrentRoute(newRoute);

    const origin = newRoute[0];
    const destination = newRoute[newRoute.length - 1];
    const waypoints =
        newRoute.length > 2
            ? newRoute
                  .slice(1, -1)
                  .map((point) => `via:${encodeURIComponent(point)}`)
                  .join('|')
            : '';

    const updatedMapUrl = waypoints
        ? `https://www.google.com/maps/embed/v1/directions?key=${apiKey}&origin=${encodeURIComponent(
              origin
          )}&destination=${encodeURIComponent(destination)}&waypoints=${waypoints}&mode=driving`
        : `https://www.google.com/maps/embed/v1/directions?key=${apiKey}&origin=${encodeURIComponent(
              origin
          )}&destination=${encodeURIComponent(destination)}&mode=driving`;

    setMapUrl(updatedMapUrl);

    // Add order details to the route list if the order exists
    if (order) {
        setRouteDetails((prevDetails) => [
            ...prevDetails,
            {
                orderId: order.id,
                customerName: `${order.recipientInfo.firstName} ${order.recipientInfo.lastName}`,
                phone: order.recipientInfo.phone || order.recipientInfo.phoneNumber || 'N/A', // Adjusted here
                address: order.recipientInfo.address,
                buzzer: order.recipientInfo.buzzer || 'N/A'
            }
        ]);
    }
};

  const handleUnwindRoute = () => {
    if (currentRoute.length > 2) {
      const newRoute = currentRoute.slice(0, -1);
      setCurrentRoute(newRoute);

      const origin = newRoute[0];
      const destination = newRoute[newRoute.length - 1];
      const waypoints =
        newRoute.length > 2
          ? newRoute
              .slice(1, -1)
              .map((point) => `via:${encodeURIComponent(point)}`)
              .join('|')
          : '';

          const updatedMapUrl = waypoints
          ? `https://www.google.com/maps/embed/v1/directions?key=${apiKey}&origin=${encodeURIComponent(
              origin
            )}&destination=${encodeURIComponent(destination)}&waypoints=${waypoints}&mode=driving`
          : `https://www.google.com/maps/embed/v1/directions?key=${apiKey}&origin=${encodeURIComponent(
              origin
            )}&destination=${encodeURIComponent(destination)}&mode=driving`;

      setMapUrl(updatedMapUrl);
    } else {
      setCurrentRoute([]);
      setMapUrl(
        `https://www.google.com/maps/embed/v1/view?key=${apiKey}&center=49.2827,-123.1207&zoom=10`
      );
    }
  };

  const printDeliveryList = () => {
    const currentDate = new Date().toLocaleString(); // Get the current date and time

    const printContent = routeDetails
      .map((detail, index) => `
        <div>
          <h3>Order ${index + 1}</h3>
          <p><strong>Customer:</strong> ${detail.customerName}</p>
          <p><strong>Phone:</strong> ${detail.phone}</p>
          <p><strong>Address:</strong> ${detail.address}</p>
          <p><strong>Buzzer:</strong> ${detail.buzzer}</p>
        </div>
        <hr />
      `)
      .join('');

    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Delivery List</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            h3 { color: #333; }
            p { margin: 5px 0; }
            hr { margin: 10px 0; }
          </style>
        </head>
        <body>
          <h2>Delivery Route List</h2>
          <p><strong>Date of Printing:</strong> ${currentDate}</p> <!-- Display the print date here -->
          ${printContent}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
};

  
  const handleLogout = () => {
    localStorage.removeItem('isAdminLoggedIn');
    navigate('/');
  };

  return (
    <div className="delivery-page-unique">
      <h2 className="delivery-header">Delivery Management</h2>

      <div>
        <h3>Visitor Count: {visitorCount}</h3> {/* Display the visitor count */}
      </div>
      <button onClick={printDeliveryList} className="btn print-btn">
         Print Delivery List
      </button>
      <div className="delivery-content-unique">
        <div className="table-container-unique">
          {orders.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Acceptance Status</th>
                  <th>Florist Status</th>
                  <th>Florist</th>
                  <th>Florist Location</th>
                  <th>Delivery Items</th>
                  <th>Delivery Address</th>
                  <th>Delivery Date</th>
                  <th>Delivery Fee</th>
                  <th>Actions</th>
                  <th>Upload Delivery Image</th>
                </tr>
              </thead>
              <tbody>
  {orders.map((order, index) => {
    const floristInfo = order.florist;
    return (
      <tr key={index}>
        <td>{order.id}</td>
        <td>{order.acceptanceStatus || 'Pending'}</td>
        <td>{order.floristStatus || 'Pending'}</td>
        <td>{floristInfo ? floristInfo.name : 'Unknown Florist'}</td>
        <td>{floristInfo ? floristInfo.address : 'Unknown Location'}</td>
        <td>
          {order.cartItems && order.cartItems.length > 0 ? (
            order.cartItems.map((item, idx) => (
              <div key={idx}>
                {item.title} - ${item.price}
              </div>
            ))
          ) : (
            <div>No items available</div>
          )}
        </td>
        <td>{order.recipientInfo?.address || 'N/A'}</td>
        <td>{order.deliveryInfo?.date || 'N/A'} {order.deliveryInfo?.time || ''}</td>
        <td>${order.deliveryFee || 0}</td>
        <td>
          {/* Mark as Delivered changes to Delivered once pressed */}
          <button disabled={order.status === 'Delivered'} onClick={() => handleMarkAsDelivered(order)}>
            {order.status === 'Delivered' ? 'Delivered' : 'Mark as Delivered'}
          </button>
          {/* Keep the other buttons active */}
          <button onClick={() => handleReassignFlorist(order)}>Reassign</button>
          <button onClick={() => handleCompleteOrder(order)}>Complete Order</button>
          <button onClick={() => handleDeleteOrder(order.id)}>Cancel</button>
          <button onClick={() => handleSetRoute(floristInfo.address, order.recipientInfo.address, order)}>Add to Route</button>
        </td>
        <td>
          {order.deliveryImage ? (
            <img src={order.deliveryImage} alt="Delivery" width="100" />
          ) : uploading === order.id ? (
            <span>Uploading...</span>
          ) : (
            <label htmlFor={`file-upload-${order.id}`}>
              <img
                src={UploadIcon}
                alt="Upload"
                width="40"
                style={{ cursor: 'pointer' }}
              />
              <input
                id={`file-upload-${order.id}`}
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(order.id, e.target.files[0])}
                style={{ display: 'none' }} // Hide the default file input
              />
            </label>
          )}
        </td>
      </tr>
    );
  })}
</tbody>

            </table>
          ) : (
            <p>No orders available.</p>
          )}
        </div>

        {showReassignModal && (
  <div className="reassign-modal-unique">
    <h3>Select a Florist to Reassign the Order</h3>
    <ul>
      {sortedFlorists.length > 0 ? (
        sortedFlorists.map((florist) => (
          <li key={florist.id}>
            <button onClick={() => handleAssignNewFlorist(florist)}>Assign to {florist.firstName}</button>
            {florist.firstName} {florist.lastName} - {florist.distance.toFixed(2)} km away
            
          </li>
        ))
      ) : (
        <p>No florists available to assign.</p>
      )}
    </ul>
    <button onClick={() => setShowReassignModal(false)} className="btn close-modal-btn-unique">
      Close
    </button>
  </div>
)}


        <div className="map-container-unique">
          <iframe
            title="Selected Delivery Route"
            width="600"
            height="400"
            frameBorder="0"
            style={{ border: 0 }}
            src={mapUrl}
            allowFullScreen
          ></iframe>
          <button onClick={handleUnwindRoute} className="btn">
            Unwind Route
          </button>
        </div>
      </div>

      <button className="btn logout-btn-unique" onClick={handleLogout}>
        Log Out
      </button>
    </div>
  );
};

export default Delivery;
