import React, { useEffect, useState } from 'react';
import { auth, db } from '../Payment/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './SupplierPayment.css';

const SupplierPayment = () => {
  const [payments, setPayments] = useState([]);
  const [accountBalance, setAccountBalance] = useState(0);
  const [supplier, setSupplier] = useState(null);

  useEffect(() => {
    const fetchPayments = async () => {
      const user = auth.currentUser;
      if (user) {
        const supplierRef = collection(db, 'suppliers');
        const supplierQuery = query(supplierRef, where('uid', '==', user.uid));
        const supplierSnapshot = await getDocs(supplierQuery);
        if (!supplierSnapshot.empty) {
          const supplierData = supplierSnapshot.docs[0].data();
          setSupplier(supplierData);

          // Fetch payments
          const paymentsRef = collection(db, 'supplierPayments');
          const paymentsQuery = query(paymentsRef, where('supplierId', '==', user.uid));
          const paymentsSnapshot = await getDocs(paymentsQuery);
          const paymentsData = paymentsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setPayments(paymentsData);

          // Calculate account balance
          const totalPaid = paymentsData.reduce((acc, payment) => acc + payment.amount, 0);
          setAccountBalance(totalPaid);
        }
      } else {
        // Redirect to login if not authenticated
        window.location.href = '/supplier/login';
      }
    };

    fetchPayments();
  }, []);

  return (
    <div className="supplier-payment">
      <h1>Supplier Payment</h1>
      <h2>Account Balance: ${accountBalance.toFixed(2)}</h2>
      {payments.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Payment ID</th>
              <th>Amount</th>
              <th>Date Paid</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <tr key={payment.id}>
                <td>{payment.id}</td>
                <td>${payment.amount.toFixed(2)}</td>
                <td>{new Date(payment.datePaid.seconds * 1000).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No payment records found.</p>
      )}
    </div>
  );
};

export default SupplierPayment;
