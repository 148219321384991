import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../Payment/firebase'; // Adjust path based on your structure
import './CustomerManagement.css';
import EmptyProfilePic from '../EmptyProfile.jpg'; // Assuming the same placeholder image

const CustomerManagement = () => {
  const [customers, setCustomers] = useState([]);
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    const fetchCustomers = async () => {
      const customerCollection = collection(db, 'customerProfiles');
      const customerSnapshot = await getDocs(customerCollection);
      const customersData = customerSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setCustomers(customersData);
    };
    fetchCustomers();
  }, []);

  const handleDeleteCustomer = async (customerId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this customer account?');
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'customerProfiles', customerId));
        setCustomers(customers.filter((customer) => customer.id !== customerId));
      } catch (error) {
        console.error('Error deleting customer:', error);
      }
    }
  };

  const handleEditPassword = (customer) => {
    setEditingCustomer(customer);
    setNewPassword(customer.password);
  };

  const handleSavePassword = async () => {
    try {
      const customerRef = doc(db, 'customerProfiles', editingCustomer.id);
      await updateDoc(customerRef, { password: newPassword });
      setCustomers(customers.map((customer) =>
        customer.id === editingCustomer.id ? { ...customer, password: newPassword } : customer
      ));
      setEditingCustomer(null);
      setNewPassword('');
    } catch (error) {
      console.error('Error updating password:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingCustomer(null);
    setNewPassword('');
  };

  return (
    <div className="customer-management-page">
      <h2 className="customer-management-header">Customer Management</h2>

      {customers.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Profile Picture</th>
              <th>Customer Name</th>
              <th>Account Name</th>
              <th>Password</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer) => (
              <tr key={customer.id}>
                <td>
                  <img
                    src={customer.profilePicture ? customer.profilePicture : EmptyProfilePic}
                    alt={`${customer.firstName} ${customer.lastName}`}
                    className="customer-management-thumbnail"
                  />
                </td>
                <td>{`${customer.firstName} ${customer.lastName}`}</td>
                <td>{customer.accountName}</td>
                <td>
                  {editingCustomer && editingCustomer.id === customer.id ? (
                    <input
                      type="text"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  ) : (
                    customer.password
                  )}
                </td>
                <td>{customer.email}</td>
                <td>
                  {editingCustomer && editingCustomer.id === customer.id ? (
                    <>
                      <button onClick={handleSavePassword} className="btn">Save</button>
                      <button onClick={handleCancelEdit} className="btn">Cancel</button>
                    </>
                  ) : (
                    <>
                      <button onClick={() => handleEditPassword(customer)} className="btn">Edit Password</button>
                      <button onClick={() => handleDeleteCustomer(customer.id)} className="btn">Delete</button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No customers available.</p>
      )}
    </div>
  );
};

export default CustomerManagement;
