import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, getDoc, updateDoc, query, collection, where, getDocs } from 'firebase/firestore';
import { db } from './Payment/firebase';
import './FloristPageApp.css';

// Utility function to sanitize inputs and prevent XSS
const sanitizeInput = (input) => {
  const element = document.createElement('div');
  element.innerText = input;
  return element.innerHTML;
};

// Email and Phone Validation Regex Patterns
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex = /^[0-9]{10,15}$/; // Basic phone number validation (can be customized)

// Password validation: At least 8 characters, one number, one special character
const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;

function FloristPageApp({ setFloristProfile, floristProfile }) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    address: '',
    email: '',
    accountName: '',
    password: '',
    consent: false,
    uid: '',
  });
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [announcement, setAnnouncement] = useState('');
  const navigate = useNavigate();

  // Fetch announcement if it exists and clear it afterward
  useEffect(() => {
    const fetchAnnouncement = async () => {
      if (floristProfile && floristProfile.accountName) {
        const floristQuery = query(
          collection(db, 'floristProfiles'),
          where('accountName', '==', sanitizeInput(floristProfile.accountName))
        );
        const querySnapshot = await getDocs(floristQuery);

        if (!querySnapshot.empty) {
          const floristDoc = querySnapshot.docs[0];
          const data = floristDoc.data();
          
          if (data.announcement) {
            setAnnouncement(sanitizeInput(data.announcement));
            await updateDoc(floristDoc.ref, { announcement: '' });
          }
        }
      }
    };

    fetchAnnouncement();
  }, [floristProfile]);

  // Check if there is an existing florist profile and set it
  useEffect(() => {
    if (floristProfile) {
      setIsSignedUp(true);
      setFormData(floristProfile);
    }
  }, [floristProfile]);

  // Save form changes to state
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Validate form inputs
  const validateForm = () => {
    if (!emailRegex.test(formData.email)) {
      alert('Please enter a valid email address.');
      return false;
    }

    if (!phoneRegex.test(formData.phoneNumber)) {
      alert('Please enter a valid phone number.');
      return false;
    }

    if (!passwordRegex.test(formData.password)) {
      alert('Password must be at least 8 characters long and include at least one number and one special character.');
      return false;
    }

    if (!formData.consent) {
      alert('You must agree to the terms and conditions.');
      return false;
    }

    return true;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const sanitizedFormData = {
        ...formData,
        firstName: sanitizeInput(formData.firstName),
        lastName: sanitizeInput(formData.lastName),
        accountName: sanitizeInput(formData.accountName),
        email: sanitizeInput(formData.email),
        phoneNumber: sanitizeInput(formData.phoneNumber),
        address: sanitizeInput(formData.address),
      };

      const floristRef = doc(db, 'floristProfiles', sanitizedFormData.accountName);
      const floristSnap = await getDoc(floristRef);

      // If the florist exists, keep the uid; otherwise, generate a new one
      if (floristSnap.exists()) {
        const existingFlorist = floristSnap.data();
        sanitizedFormData.uid = existingFlorist.uid;
      } else {
        sanitizedFormData.uid = `florist_${Date.now()}`;
      }

      // Save the florist profile to Firestore
      await setDoc(floristRef, sanitizedFormData);

      setFloristProfile(sanitizedFormData);
      setIsSignedUp(true);

      // Store essential non-sensitive profile info in localStorage (avoid password)
      localStorage.setItem('floristProfile', JSON.stringify({
        firstName: sanitizedFormData.firstName,
        lastName: sanitizedFormData.lastName,
        accountName: sanitizedFormData.accountName,
        email: sanitizedFormData.email,
        phoneNumber: sanitizedFormData.phoneNumber,
        uid: sanitizedFormData.uid,
      }));

      navigate('/FloristPage');
    } catch (error) {
      console.error('Error saving profile to Firestore:', error);
    }
  };

  // Navigate to Sales Analysis page
  const handleSalesAnalysisClick = () => {
    navigate('/sales-analysis');
  };

  // Check for floristProfile in localStorage on initial load
  useEffect(() => {
    const savedProfile = localStorage.getItem('floristProfile');
    if (savedProfile) {
      const parsedProfile = JSON.parse(savedProfile);
      
      setFloristProfile(parsedProfile);
    } else {
          }
  }, [setFloristProfile]);

  return (
    <div className="signup-page">
      <div className="florist-form-container">
        {!isSignedUp ? (
          <form className="florist-signup-form" onSubmit={handleSubmit}>
            {/* Form Fields */}
            <div className="florist-form-group">
              <label htmlFor="firstName">First Name:</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="florist-form-group">
              <label htmlFor="lastName">Last Name:</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="florist-form-group">
              <label htmlFor="phoneNumber">Phone Number:</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="florist-form-group">
              <label htmlFor="address">Address:</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="florist-form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="florist-form-group">
              <label htmlFor="accountName">Account Name:</label>
              <input
                type="text"
                id="accountName"
                name="accountName"
                value={formData.accountName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="florist-form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="florist-checkbox-group">
              <label htmlFor="consent">
                <input
                  type="checkbox"
                  id="consent"
                  name="consent"
                  checked={formData.consent}
                  onChange={handleChange}
                  required
                />
                I agree to the terms and conditions
              </label>
            </div>
            <button type="submit" className="florist-btn">Sign Up</button>
          </form>
        ) : (
          <div className="welcome-message">
            <h3>Welcome back, {floristProfile.firstName} {floristProfile.lastName}!</h3>
            <p>Let's start making flowers!</p>
            {announcement && <p className="announcement">{announcement}</p>}
            <button className="florist-btn" onClick={handleSalesAnalysisClick}>
              Go to Sales Analysis
            </button>
          </div>
        )}
      </div>

      <div className="florist-footer">
        <button onClick={() => navigate('/')} className="florist-btn">Back to Home</button>
      </div>
    </div>
  );
}

export default FloristPageApp;
