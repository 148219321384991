import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../Payment/firebase'; // Include storage import for Firebase
import './SupplierProfiles.css';

const SupplierProfiles = () => {
  const [suppliers, setSuppliers] = useState([]);
  const navigate = useNavigate();
  const [defaultProfileURL, setDefaultProfileURL] = useState('');

  useEffect(() => {
    // Fetch default profile picture URL from Firebase Storage
    const fetchDefaultProfile = async () => {
      try {
        const defaultRef = ref(storage, 'EmptyProfile.jpg');
        const url = await getDownloadURL(defaultRef);
        setDefaultProfileURL(url);
      } catch (error) {
        console.error('Error fetching default profile picture from Firebase:', error);
      }
    };

    fetchDefaultProfile();

    // Fetch supplier profiles from Firestore
    const fetchSuppliers = async () => {
      try {
        const supplierCollection = collection(db, 'suppliers');
        const supplierSnapshot = await getDocs(supplierCollection);
        const supplierList = supplierSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSuppliers(supplierList);
      } catch (error) {
        console.error('Error fetching supplier profiles:', error);
      }
    };

    fetchSuppliers();
  }, []);

  const handleSupplierClick = (supplierId) => {
    navigate(`/supplier-product/${supplierId}`);
  };

  return (
    <div className="supplier-profiles-container">
      <h1>Supplier Stores: Places to get your flower stock!</h1>
      <div className="supplier-list">
        {suppliers.map((supplier) => (
          <button
            key={supplier.id}
            className="supplier-button"
            onClick={() => handleSupplierClick(supplier.id)}
          >
            <img
              src={supplier.profilePicture || defaultProfileURL}
              alt={`${supplier.companyName} Profile`}
              className="supplier-thumbnail"
              onError={(e) => {
                // If the image fails to load, fall back to the default profile image from Firebase
                e.target.src = defaultProfileURL;
              }}
            />
            <div className="supplier-info">
              <h3>{supplier.companyName}</h3>
              <p>{supplier.contactPerson}</p>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default SupplierProfiles;
